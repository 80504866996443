//
// ─── EXPORTING MODULE FUNCTION ──────────────────────────────────────────────────
//

module.exports = function () {
	$('.stars-readOnly').each(function(index, item) {
		var $singleRating = $(item).barrating({
			theme: 'fontawesome-stars-o',
			initialRating: (($(item).is('[data-init]') && $(item).attr('data-init') !== '0') ? Number($(item).attr('data-init')) : 'null'),
			readonly: true,
			showSelectedRating: true,
			hoverState: false,
			silent: true
		});
		if ($(item).is('[data-init]')) {
			$singleRating.closest('.br-wrapper').find('.br-widget .br-current-rating').text(Number($(item).attr('data-init')));
		}
	});
	$('.stars-input').each(function(index, item) {
		var $singleRating = $(item).barrating({
			theme: 'fontawesome-stars-o',
			initialRating: (($(item).is('[data-init]') && $(item).attr('data-init') !== '0') ? Number($(item).attr('data-init')) : 'null'),
			readonly: false,
			showSelectedRating: true,
			hoverState: true,
			silent: false
		});
		if ($(item).is('[data-init]')) {
			$singleRating.closest('.br-wrapper').find('.br-widget .br-current-rating').text(Number($(item).attr('data-init')));
		}
	});
}