const Arabic  = require('flatpickr/dist/l10n/ar.js').default.ar;
const English = require('flatpickr/dist/l10n/default.js');

module.exports = function () {
	// const timeline = {
	// 	animation_enterance    : "fadeIn",
	// 	animation_exit         : "fadeOut",
	// 	animation_speed        : "fast",
	// 	max_number_of_timelines: 10,
	// 	init() {
	// 		this.cach_dom();
	// 		this.bind_events();
	// 	},
	// 	cach_dom() {
	// 		this.$timeline                       = $('#timeline');
	// 		this.$timeline_container             = this.$timeline.find("#timelineContainer");
	// 		this.$timeline_cards                 = this.$timeline_container.find('.card-date-range[data-initialized-timeline]');
	// 		this.$timeline_cards_invisable       = this.$timeline_container.find('.card-date-range[data-initialized-timeline="false"]');
	// 		this.$timeline_calender_unintialized = this.$timeline_cards_invisable.find('.inputDatePickerWithRange');
	// 		this.$timeline_add                   = this.$timeline.find('.card-date-range-addNew');
	// 		this.$timeline_remove                = this.$timeline_cards.find('.card-date-range-remove');

	// 		if (this.$timeline_cards.length - this.$timeline_cards_invisable.length == this.max_number_of_timelines && !this.$timeline_add.hasClass('d-none')) {
	// 			this.$timeline_add.addClass('d-none');
	// 		} else  {
	// 			this.$timeline_add.removeClass('d-none');
	// 		}

	// 	},
	// 	bind_events() {
	// 		this.$timeline_add.on('click', this.render.bind(this));
	// 		this.$timeline_remove.on('click', this.remove_card.bind(this));
	// 	},
	// 	close_events() {
	// 		this.$timeline_add.off();
	// 		this.$timeline_remove.off();
	// 	},
	// 	render() {
	// 		var index = this.$timeline_cards.length - this.$timeline_cards_invisable.length;
	// 		if(index < this.max_number_of_timelines) {
	// 			$(this.$timeline_cards[index]).attr('data-initialized-timeline', "true").animateCss(`${this.animation_enterance} ${this.animation_speed}`);
	// 		} else {
	// 			this.$timeline_add.addClass('d-none');
	// 		}
	// 		this.close_events();
	// 		this.cach_dom();
	// 		this.bind_events();
	// 	},
	// 	remove_card(e) {
	// 		var $parent = $(e.target).parent();
	// 		if ($parent.attr('data-initialized-timeline') === 'true') {
	// 			$parent.attr('data-initialized-timeline', "false")
	// 			$parent.find('input').val('');
	// 			$parent.clone().appendTo(timeline.$timeline_container);
	// 			$parent.remove();
	// 		}
	// 		this.close_events();
	// 		this.cach_dom();
	// 		this.bind_events();
	// 		this.init_calender();
	// 	},
	// 	init_calender() {
	// 		$(this.$timeline_calender_unintialized).each(function (index, item) {
	// 			$(item).flatpickr({
	// 				mode: "range",
	// 				minDate: "today",
	// 				enableTime: false,
	// 				dateFormat: "Y-m-d",
	// 				"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
	// 			});
	// 		})
	// 	}
	// };
	// timeline.init();
}