module.exports = function () {

	var $dir = $('html').attr('dir');
	var $filterButton = $('button[data-filter-target*=-manager-filter]');
	var $filterButtonText = $filterButton.text();
	var $filterForm   = $('div[data-filter="' + $filterButton.data('filter-target') + '"]');
	// var $selectInputs = $filterForm.find('select[id*="ManagerFilter"]');
	var $selectInputs = $filterForm.find('select.selectize-select');
	var $resetButton  = $filterForm.find('button[type="reset"]');
	// $selectInputs.each(function (index, item) {
	// 	$(item).selectize({
	// 		create: false,
	// 		placeholder: $(this).attr('placeholder'),
	// 		sortField: 'text',
	// 		addPrecedence: false
	// 	});
	// });
	$filterButton.on('click', function (e) {
		e.preventDefault();
		var trigerButton = $filterButton.data('filter-target');
		var $target      = $(document).find(`div[data-filter="${trigerButton}"]`);
		if ($target.hasClass('d-none') && $filterButton.data('shown') === false) {
			$target.removeClass('d-none').animateCss('fadeIn');
			$filterButton.text(($dir === 'rtl') ? 'إغلاق' : 'close');
			$filterButton.attr('data-shown', true);
		} else {
			$filterButton.text($filterButtonText);
			$target.animateCss('fadeOut faster', function () {
				$target.addClass('d-none');
				$filterButton.attr('data-shown', false);
			});
		}
	});


	$resetButton.on('click', function (e) {
		e.preventDefault();
		reset();
	});

	function reset() {
		$filterForm.find('input, select').each(function (index, item) {
			$(item).val('');
		});
		$selectInputs.each(function (index, item) {
			$(item)[0].selectize.clear();
		});
		$filterForm.find('form').submit();
	}
}