//
// ─── 1- DEPENDENCIES ───────────────────────────────────────────────────────────────
//

window.$ = window.jQuery = require('jquery');  // adding jquery to window object
require('popper.js'); // requiring popper.js, bootstrap requiring it based on bootstrap documentation.
require('bootstrap'); // requiring bootstrap script
// require('jquery-ui-sortable-npm'); // NOTE: enable this if selectize needs sort options
require('selectize'); // https://selectize.github.io/selectize.js/
require('jquery-bar-rating'); // requiring jquery-bar-rating plugin for rating stars.
require("flatpickr"); // requireing flatpickr plugin to activate calender input.
require('truncate.js');
var tagsForm                = require('./partials/tagsForm');
var timeline                = require('./partials/timeline');
var highlight               = require('./partials/highlight');
var swiperInit              = require('./partials/swiperInit');
var googleMaps              = require('./partials/googleMaps');
var overlayMenu             = require('./partials/overlayMenu');
var UploadFiles             = require('./partials/uploadFiles');
var chartjsInit             = require('./partials/chartjsInit');
var serviceForm             = require('./partials/serviceForm');
var toolTipInit             = require('./partials/tooltipInit');
var tinyMCEInit             = require('./partials/tinyMCEInit');
var headerScroll            = require('./partials/headerScroll');
var jarallaxInit            = require('./partials/jarallaxInit');
var barRatingInit           = require('./partials/barRatingInit');
var flatpickrInit           = require('./partials/flatPickrInit');
var selectizeInit           = require('./partials/selectizeInit');
var animateCssInit          = require('./partials/animateCssInit');
var formValidation          = require('./partials/formValidation');
var truncateTextInit        = require('./partials/truncateTextInit');
var messageScrollBar        = require('./partials/messagesScrollBar');
var intlTelInput_jquery 	= require('./partials/intlTelInput-jquery');
var intlTelInputInit        = require('./partials/intlTelInputInit');
var chainedInputInit        = require('./partials/chainedInputsInit');
var innerManagerFilter      = require('./partials/innerManagerFilter');
var serviceFilteration      = require('./partials/services-filteration');
var fileInputChangeLabel    = require('./partials/fileInputChangeLabel');
var inputNumberValidation   = require('./partials/inputNumberValidation');
var bootstrapModalAnimation = require('./partials/bootstrapModalAnimation');
var formMultiLocale = require('./partials/formMultiLocale');
var inputFunc = require('./partials/inputFunc');
var langSelect = require('./partials/langSelect');
var fancyBox = require('./partials/fancyBox');
var repeatEls = require('./partials/repeatEls');
var inputVals = require('./partials/inputVals');





//
// ─── 2- MAIN FUNCTION ──────────────────────────────────────────────────────────────
//

(function ($) {
	$(document).ready(() => {
		animateCssInit(); // initiliaing animate.css callback method.
		highlight(); // hightlight plugin for wrapping searched words with <mark class="mark"></mark> tag.
		overlayMenu(); // sidemenu script, feel free to enhance code.
		inputNumberValidation(); // preventing input[type="number"] from submitting [-,e] values.
		messageScrollBar(); // scrolling all the way down to messages chat room to see last message.
		swiperInit(); // initializing all sliders in the system using SWiper.js carousel.
		tinyMCEInit(); // initializing all texteditors based on textare inputs, feel free to enhance code.
		flatpickrInit(); // initialize all flatpickr for time and date calender inputs, using .inputDatePicker for calender, and .inputTimePicker for time input.
		toolTipInit(); // initialize bootstrap tooltip functionality.
		bootstrapModalAnimation(); // Bootstrap modals on 'show' & on 'hide' animation.
		// headerScroll(); // NOTE: uncomment this to transparent header for home page unless on scrolling to get back for it's default background color.
		// formValidation(); // NOTE: uncomment this if you want to prevent all forms to submit before all input fields valid.
		UploadFiles(); // file inputs validation and preview images for all file inputs, feel free to enhance code.
		barRatingInit(); // initializing jquery-bar-rating plugin.
		jarallaxInit(); // initializing jarallax plugin for parallax effect in home page.
		// serviceForm();
		innerManagerFilter(); // dashboard filteration toggle function.
		tagsForm(); // Tag Module checkboxes inputs.
		selectizeInit(); // initializing selectize plugin.
		serviceFilteration(); // don't remember what this function doing but keep it as it is :)
		intlTelInput_jquery();
		intlTelInputInit(); // initializing intl-tel-input for phone and mobile numers.
		chainedInputInit(); // initializing jquery-chained plgin for categories and nested categories inputs.
		timeline();
		googleMaps.initAutocomplete(); // google map search functionality, initialize google map search in all system.
		googleMaps.initMap(); // single map functionality, till now it's only used in services-single.html
		chartjsInit(); // single file that hold all charts initialization for Reports module.
		fileInputChangeLabel();
		truncateTextInit();
		// formMultiLocale();
		inputFunc();
		langSelect();
		fancyBox();
		repeatEls();
		inputVals();
	});





})(jQuery);


// general scripts
