const weeklyWorkingHours = require('./weeklyWorkingHours');
const dailyWorkingHours  = require('./dailyWorkingHours');
const Arabic             = require('flatpickr/dist/l10n/ar.js').default.ar;
const English            = require('flatpickr/dist/l10n/default.js');

module.exports = function () {
	weeklyWorkingHours();
	dailyWorkingHours.init();


	//  time inputs picker
	$(".inputTimePicker").flatpickr({
		enableTime: true,
		noCalendar: true,
		dateFormat: "h:i K",
		"locale"  : ($('html').attr('dir') === 'rtl') ? Arabic : English
	});
	$('.inputDatePicker').flatpickr({
		enableTime: false,
		"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
		dateFormat: "Y-m-d"
	});
	$(".inputSlotPicker").flatpickr({
		enableTime: true,
		noCalendar: true,
		time_24hr : true,
		dateFormat: "H:i",
		minDate   : ($(this).attr('data-time-default') || "00:30"),
		maxDate   : "24:00",
		"locale"  : ($('html').attr('dir') === 'rtl') ? Arabic    : English
	});

	// var minimumTime;
	$(".inputTimePickerFrom").flatpickr({
		enableTime: true,
		noCalendar: true,
		dateFormat: "h:i K",
		"locale"  : ($('html').attr('dir') === 'rtl') ? Arabic : English
	});

	$(".inputTimePickerTo").flatpickr({
		enableTime: true,
		noCalendar: true,
		dateFormat: "h:i K",
		"locale"  : ($('html').attr('dir') === 'rtl') ? Arabic : English
	});

	if ($('#formInput_daysOffPerYear').length){
		$('#formInput_daysOffPerYear').flatpickr({
			enableTime: false,
			mode: "multiple",
			dateFormat: "Y-m-d",
			defaultDate: `${($('#formInput_daysOffPerYear').val() !== '') ? $('#formInput_daysOffPerYear').val().split(',') : ''}`,
			"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English
		});
	}



	// ****************************************************
	// *			initializing units input              *
	// ****************************************************
	$('select#formInput_units').selectize({
		create       : false,
		placeholder  : `${($('html').attr('dir') === 'ltr') ? 'Unit*' : 'الوحدة *'}`,
		sortField    : 'text',
		addPrecedence: false,
	}).on('change', function () {
		// caching dom
		var unitValue           = $(this).val().toLowerCase();
		var $addServiceForm     = $('#serviceForm');
		var $dailyWorkingHours  = $addServiceForm.find('#dailyWorkingHours');
		var $weeklyWorkingHours = $addServiceForm.find('#weeklyWorkingHours');
		var $slotValue          = $addServiceForm.find('#formInput_slot').parent().parent();
		var $deliverTime        = $addServiceForm.find('#formInput_deliverTime').parent().parent();
		var $checkInTime        = $addServiceForm.find("#formInput_checkInTime").parent().parent();
		var $checkOutTime       = $addServiceForm.find("#formInput_checkOutTime").parent().parent();
		var enteranceAnimation  = 'fadeIn faster';
		var exitAnimation       = 'fadeOut faster';


		// handeling unit values
		switch (unitValue) {
			case 'project':
				//* this case will show "weekly working hours" inputs and hide inputs for other cases
				if ($deliverTime.hasClass('d-none')) {
					//* reseting "weekly working hours" inputs to its default values, also remove require from inputs for validations purpose.
					$deliverTime.find('input').val('');
					$deliverTime.find('input').attr('required', true)
					$deliverTime.removeClass('d-none').animateCss(enteranceAnimation);
				}
				if (!$slotValue.hasClass('d-none') && !$dailyWorkingHours.hasClass('d-none') && !$weeklyWorkingHours.hasClass('d-none')) {
					$slotValue.addClass('d-none');
					$dailyWorkingHours.addClass('d-none');
					$slotValue.find('input[required]').removeAttr('requierd');
					$dailyWorkingHours.find('input[required]').removeAttr('required');
					$dailyWorkingHours.find('#dailyWorkingHoursContainer').html('');
					$weeklyWorkingHours.addClass('d-none');
					$weeklyWorkingHours.find('input[required]').removeAttr('required');
				}
				if (!$checkInTime.hasClass('d-none') && !$checkOutTime.hasClass('d-none')) {
					$checkInTime.addClass('d-none');
					$checkOutTime.addClass('d-none');
				}
				break;
			case 'slot':
				//* this case will show "slot & daily working hours" inputs and hide inputs for other cases
				if ($slotValue.hasClass('d-none') && $dailyWorkingHours.hasClass('d-none') && $weeklyWorkingHours.hasClass('d-none')) {
					$slotValue.find('input').val('');
					$slotValue.removeClass('d-none').animateCss(enteranceAnimation);
					$dailyWorkingHours.find('#dailyWorkingHoursContainer').html('');
					dailyWorkingHours.reset();
					$dailyWorkingHours.find('input').prop('required', true);
					$dailyWorkingHours.removeClass('d-none').animateCss(enteranceAnimation);
					$weeklyWorkingHours.find('input[type="checkbox"]:checked').prop('checked', false);
					$weeklyWorkingHours.find('div[data-switch-target]').addClass('invisible');
					$weeklyWorkingHours.find('input[type="text"]').val('');
					$weeklyWorkingHours.removeClass('d-none').animateCss(enteranceAnimation);
				}
				if (!$deliverTime.hasClass('d-none')) {
					$deliverTime.addClass('d-none');
					$deliverTime.find('input[required]').removeAttr('required');
				}
				if (!$checkInTime.hasClass('d-none') && !$checkOutTime.hasClass('d-none')) {
					$checkInTime.addClass('d-none');
					$checkOutTime.addClass('d-none');
				}
				break;
			case 'night':
				//* this case will show "checkin & checkout" inputs and hide inputs for other cases
				if ($checkInTime.hasClass('d-none') && $checkOutTime.hasClass('d-none')) {
					$checkInTime.find('input').val('');
					$checkOutTime.find('input').val('');
					$checkInTime.removeClass('d-none').animateCss(enteranceAnimation);
					$checkOutTime.removeClass('d-none').animateCss(enteranceAnimation);
				}
				if (!$deliverTime.hasClass('d-none')) {
					$deliverTime.addClass('d-none');
					$deliverTime.find('input[required]').removeAttr('required');
				}
				if (!$slotValue.hasClass('d-none') && !$dailyWorkingHours.hasClass('d-none') && !$weeklyWorkingHours.hasClass('d-none')) {
					$slotValue.addClass('d-none');
					$dailyWorkingHours.addClass('d-none');
					$slotValue.find('input[required]').removeAttr('requierd');
					$dailyWorkingHours.find('input[required]').removeAttr('required');
					$dailyWorkingHours.find('#dailyWorkingHoursContainer').html('');
					$weeklyWorkingHours.addClass('d-none');
					$weeklyWorkingHours.find('input[required]').removeAttr('required');
				}
				break;
			case 'hour':
				//* this case hides all inputs for other cases
				if (!$deliverTime.hasClass('d-none')) {
					$deliverTime.addClass('d-none');
					$deliverTime.find('input[required]').removeAttr('required');
				}
				if (!$slotValue.hasClass('d-none') && !$dailyWorkingHours.hasClass('d-none') && !$weeklyWorkingHours.hasClass('d-none')) {
					$slotValue.addClass('d-none');
					$dailyWorkingHours.addClass('d-none');
					$slotValue.find('input[required]').removeAttr('requierd');
					$dailyWorkingHours.find('input[required]').removeAttr('required');
					$dailyWorkingHours.find('#dailyWorkingHoursContainer').html('');
					$weeklyWorkingHours.addClass('d-none');
					$weeklyWorkingHours.find('input[required]').removeAttr('required');
				}
				if (!$checkInTime.hasClass('d-none') && !$checkOutTime.hasClass('d-none')) {
					$checkInTime.addClass('d-none');
					$checkOutTime.addClass('d-none');
				}
				break;
		}

	});

};