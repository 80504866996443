module.exports = function () {

	var invalid_chars = ['-', 'e'];

	$('input[type="number"]').each(function (index, item) {
		$(item).on('keydown', function (e) {
			if(invalid_chars.includes(e.key)) {
				e.preventDefault();
			}
		});
	});
}