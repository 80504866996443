module.exports = function () {
	var $buttons = $('[data-toggle="modal"]');
	var enteranceAnimation = 'fadeIn faster';
	var exitAnimation = 'fadeOut faster';
	$buttons.each(function (index, button) {
		var target = $(button).data('target');
		var $modal = $(`${target}`);
		var $closeBtn = $modal.find('.close');

		$(button).on('click', function (e) {
			console.log('modals working');
			e.preventDefault();
			$modal.animateCss(`${enteranceAnimation}`, function () {
				$modal.modal('show');
			});
		});
		$closeBtn.on('click', function (e) {
			e.preventDefault();
			e.stopImmediatePropagation();
			$modal.animateCss(`${exitAnimation}`, function () {
				$modal.modal('hide');
			});
		});
	});
}