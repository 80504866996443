//
// ─── LOADING DEPENDENCIES ───────────────────────────────────────────────────────
//
const Chart = require('chart.js');
const themeColors = require('./sharedThemeColors');


//
// ─── EXPORTING MODULE FUNCTION ──────────────────────────────────────────────────
//
module.exports = function () {

	//
	// ─── CACHING DOM ────────────────────────────────────────────────────────────────
	//
	let $lineChart = $('canvas.line');
	let $pieChart = $('canvas.pie');



	//
	// ─── INITIALIZING CHARTS ────────────────────────────────────────────────────────
	//
	$lineChart.each(function(index, item) {
		var data = JSON.parse($(item).parent().attr('data-json')) || {};
		let lineChartOptions = {
			type: 'line', // this for specifing chart type.
			data: {
				labels: Object.keys(data),
				datasets: [{
					label: '',
					backgroundColor: themeColors.transparent,
					borderColor: themeColors.primary,
					borderWidth: 1,
					lineTension: 0, // Bezier curve tension of the line, Set to 0 to draw straightlines.
					pointHoverBackgroundColor: themeColors.red,
					pointHoverBorderColor: themeColors.red,
					pointBackgroundColor: themeColors.gray,
					pointBorderColor: themeColors.transparent,
					showLine: true, // If false, the line is not drawn for this dataset.
					fill: false,
					data: Object.values(data)
				}]
			},
			options: {
				legend: {
					display: false
				},
				scales: {
					xAxes: [{
						gridLines: {
							color: themeColors.transparent,
						}
					}],
					yAxes: [{
						gridLines: {
							color: themeColors.transparent,
						}
					}]
				}
			}
		};
		new Chart($(item), lineChartOptions)
	});



	$pieChart.each(function (index, item) {
		var data = JSON.parse($(item).parent().attr('data-json')) || {};
		let pieChartOptions = {
			type: 'pie', // this for specifing chart type.
			data: {
				labels: Object.keys(data),
				datasets: [{
					label: '',
					backgroundColor: [themeColors.blue, themeColors.red, themeColors.green, themeColors.grayLight],
					borderColor: [themeColors.blue, themeColors.red, themeColors.green, themeColors.grayLight],
					borderWidth: 0,
					data: Object.values(data)
				}]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: false
				},
				animation: {
					onComplete: function () {
						var ctx = this.chart.ctx;
						ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
						ctx.textAlign = 'center';
						ctx.textBaseline = 'bottom';

						this.data.datasets.forEach(function (dataset) {

							for (var i = 0; i < dataset.data.length; i++) {
								var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
									total = dataset._meta[Object.keys(dataset._meta)[0]].total,
									mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
									start_angle = model.startAngle,
									end_angle = model.endAngle,
									mid_angle = start_angle + (end_angle - start_angle) / 2;

								var x = mid_radius * Math.cos(mid_angle);
								var y = mid_radius * Math.sin(mid_angle);

								ctx.fillStyle = '#fff';

								var percent = String((dataset.data[i] / total * 100).toFixed(2)) + "%";
								//Don't Display If Legend is hide or value is 0
								if (dataset.data[i] != 0 && dataset._meta[0].data[i].hidden != true) {
									// ctx.fillText(dataset.data[i], model.x + x, model.y + y);
									// Display percent in another line, line break doesn't work for fillText
									ctx.fillText(percent, model.x + x, model.y + y + 15);
								}
							}
						});
					}
				}
			}
		};

		new Chart($(item), pieChartOptions)
	});


}