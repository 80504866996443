module.exports = () => {
	const UploadFile = {
		filteredList: [],
		init: function() {
			this.cacheDom();
			this.bindEvents();
		},
		cacheDom: function() {
			this.$fileInputs = $('input.custom-file-input[id*="UploadFile-"]');
		},
		bindEvents: function() {
			this.$fileInputs.each(function(index, input) {
				$(input).on('change', function (e) {
					var maxSize = $(e.target).attr('data-maxSize') || 1;
					this.filteredList = Array.from(e.target.files).filter((file) => {
						return file.size <= maxSize * 1024 * 1024;
					});
					if(this.filteredList.length) {
						console.log('truee')
						$(e.target).next().next().css('display','none')
					} else {
						$(e.target).next().next().css('display','block')
					}
					
						UploadFile.render(e.target, this.filteredList);
					
				});
			});
		},
		render: function(target, files) {
			var targetId = $(target).attr('id');
			var maxFiles = $(target).attr('data-maxFiles') || 1;
			this.$previewContainer = $(document).find(`div[data-preview="${targetId}"]`);
			this.$previewContainer.html('');
			if($(target).hasClass('is-invalid')){
				$(target).removeClass('is-invalid');
			}
			if(files.length > maxFiles) {
				if($(target).hasClass('is-valid')) {$(target).removeClass('is-valid')};
				$(target).addClass('is-invalid');
				return;
			}
			if(files.length <= maxFiles && !$(target).hasClass('is-valid')){
				$(target).addClass('is-valid');
			}
			for(var i = 0 ; i < files.length; i++) {
				this.$previewContainer.append(`
					<div class="file-upload-preview animated fadeInUp">
						<div class="file-upload-info">
							${(this.fileImage(files[i]) ? `<img class="file-upload-img" src="${this.fileImage(files[i])}" alt="admin image"/>` : `<div class="file-upload-attatch"><i class="material-icons">attachment</i></div>`)}
							<p class="file-upload-name">${files[i].name}</p>
						</div>
					</div>
				`);
			}
		},
		fileImage: function(file) {
			if (file.type.match(/image.*/)) {
				return window.URL.createObjectURL(file);
			}
		},

	};


	UploadFile.init();
};