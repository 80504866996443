const Arabic = require('flatpickr/dist/l10n/ar.js').default.ar;
const English = require('flatpickr/dist/l10n/default.js');


const dailyWorkingHours = (function () {

	// Todo: get value from inputs in edit fields


	// ****************************************************
	// *			options		                          *
	// ****************************************************
	var timeFormat = "h:i K";
	var dateFormat = "Y-m-d";
	var disabledDates = [];
	var animations = {
		change: `pulse faster`,
		enterance: `fadeIn faster`,
		exit: 'fadeOut faster'
	};

	// ****************************************************
	// *			Caching Dom		                      *
	// ****************************************************
	var $mainContainer = $("#dailyWorkingHours");
	var $cardContainer = $mainContainer.find('#dailyWorkingHoursContainer');
	var $cardsLength = $cardContainer.find('.card-date-range').length;
	var $addBtn = $mainContainer.find('.card-date-range-addNew');
	var $removeBtn = $cardContainer.find('.card-date-range-remove');
	var $dateRangeNum = ($cardsLength > 0) ? $cardsLength : 1;


	// ****************************************************
	// *			Render View Function                  *
	// ****************************************************
	var renderView = function (elementIndex) {
		$cardContainer.append(`
			<div class="card-date-range animated ${animations.enterance}">
				${(elementIndex > 1) ? '<i class="card-date-range-remove material-icons">close</i>' : ''}
				<div class="card-body">
					<h6 class="card-subtitle">select date range</h6>
					<div class="row">
						<div class="col-6 col-md-12 col-lg-6">
							<div class="form-float-label-group mb-0">
								<input class="form-control form-float-control inputServiceDatePickerFrom" data-init="false" id="serviceDailyDateFrom-${elementIndex}" name="service_daily_working_hours[${elementIndex-1}][day_from]" type="text" placeholder="From Day *"  required/>
								<small class="form-text invalid-feedback">From day is required</small>
								<small class="form-text valid-feedback">From day is valid</small>
								<label for="serviceDailyDateFrom-${elementIndex}">From Day *</label>
							</div>
						</div>
						<div class="col-6 col-md-12 col-lg-6">
							<div class="form-float-label-group mb-0">
								<input class="form-control form-float-control inputServiceDatePickerTo" data-init="false" id="serviceDailyDateTo-${elementIndex}" name="service_daily_working_hours[${elementIndex-1}][day_to]" type="text" placeholder="To day *" required/>
								<small class="form-text invalid-feedback">To day is required</small>
								<small class="form-text valid-feedback">To day is valid</small>
								<label for="serviceDailyDateTo-${elementIndex}">To day *</label>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body invisible">
					<h6 class="card-subtitle">Working hours for:<span></span></h6>
					<div class="row">
						<div class="col-6 col-md-12 col-lg-6">
							<div class="form-float-label-group mb-0">
								<input class="form-control form-float-control inputServiceTimePickerFrom" data-init="false" id="serviceDailyHoursFrom-${elementIndex}" name="service_daily_working_hours[${elementIndex-1}][hour_from]" type="text" placeholder="From hour *" required/>
								<small class="form-text invalid-feedback">From Hour is required</small>
								<small class="form-text valid-feedback">From Hour is valid</small>
								<label for="serviceDailyHoursFrom-${elementIndex}">From hour *</label>
							</div>
						</div>
						<div class="col-6 col-md-12 col-lg-6">
							<div class="form-float-label-group mb-0">
								<input class="form-control form-float-control inputServiceTimePickerTo" data-init="false" id="serviceDailyHoursTo-${elementIndex}" name="service_daily_working_hours[${elementIndex-1}][hour_to]" type="text" placeholder="To hour *" required/>
								<small class="form-text invalid-feedback">To Hour is required</small>
								<small class="form-text valid-feedback">To Hour is valid</small>
								<label for="serviceDailyHoursTo-${elementIndex}">To hour *</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		`);
		$removeBtn = $cardContainer.find('.card-date-range-remove');
		$removeBtn.each(function (index, item) {
			$(item).on('click', function () {
				$(item).closest('.card-date-range').animateCss(animations.exit, function () {
					$(item).closest('.card-date-range').remove();
					disabledDates.splice(index + 1, 1);
				});
			})
		});
		initFields();
	}

	// ****************************************************
	// *			Initial Fields Function			      *
	// ****************************************************
	var initFields = function () {
		var dateFrom = '';
		var dateTo = '';
		$(".inputServiceDatePickerFrom[data-init=false]").each(function (indexFrom, itemFrom) {
			// disabledDates[indexFrom] = {
			// 	from: $(itemFrom).val(),
			// 	to: $(".inputServiceDatePickerTo[data-init='false']").eq(indexFrom).val()
			// }
			// console.log(disabledDates);
			$(itemFrom).flatpickr({
				enableTime: false,
				"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
				minDate: "today",
				dateFormat: dateFormat,
				disable: disabledDates,
				onReady: function (selectedDatesFrom, dateStrFrom, instanceFrom) {
					if ($(instanceFrom.element).attr('data-init') === 'false'){
						$(instanceFrom.element).attr('data-init', 'true');
					}
				},
				onOpen: function (selectedDatesFromOpend, dateStrFromOpend, instanceFromOpend) {
					for (var i = 0; i < disabledDates.length; i++) {
						if (disabledDates[i].from === $(instanceFromOpend.element).val()) {
							disabledDates.splice(i, 1);
						}
					}
					instanceFromOpend.set('disable', disabledDates);
				},
				onChange: function (selectedDatesFrom, dateStrFrom, instanceFrom) {
					var $nextStipDate = $(instanceFrom.element).closest('.card-body').next('.card-body');
					if ($nextStipDate.hasClass('invisible')) {
						$nextStipDate.removeClass('invisible').animateCss(animations.enterance);
					}
					dateFrom = dateStrFrom;
					var dateString = `${dateFrom} : ${dateTo}`;
					$nextStipDate.find('.card-subtitle span').html(`${dateString}`);
					$nextStipDate.find('.card-subtitle span').animateCss(animations.change);

					$(".inputServiceDatePickerTo[data-init='false']").each(function (indexTo, itemTo) {
						$(itemTo).flatpickr({
							enableTime: false,
							"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
							minDate: "today",
							dateFormat: dateFormat,
							disable: disabledDates,
							onReady: function (selectedDatesTo, dateStrTo, instanceTo) {
								if ($(instanceTo.element).attr('data-init') === 'false') {
									$(instanceTo.element).attr('data-init', 'true');
								}
							},
							onOpen: function (selectedDatesToOpend, dateStrToOpend, instanceToOpend) {
								instanceToOpend.set('disable', disabledDates);
							},
							onChange: function (selectedDatesTo, dateStrTo, instanceTo) {
								var $nextStipDate = $(instanceTo.element).closest('.card-body').next('.card-body');
								if ($nextStipDate.hasClass('invisible')) {
									$nextStipDate.removeClass('invisible').animateCss(animations.enterance);
								}
								dateTo = dateStrTo;
								var dateString = `${dateFrom} : ${dateTo}`;
								$nextStipDate.find('.card-subtitle span').html(`${dateString}`)
								$nextStipDate.find('.card-subtitle span').animateCss(animations.change);

								disabledDates.push({
									from: dateFrom,
									to: dateTo
								});
							}
						});
					});
				}
			});
		});
		$(".inputServiceTimePickerFrom[data-init='false']").flatpickr({
			enableTime: true,
			noCalendar: true,
			dateFormat: timeFormat,
			"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
			onChange: function (selectedDates, dateStr, instance) {
				$(instance.element).attr('data-init', 'true')
			}
		});

		$(".inputServiceTimePickerTo[data-init='false']").flatpickr({
			enableTime: true,
			noCalendar: true,
			dateFormat: timeFormat,
			"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
			onChange: function (selectedDates, dateStr, instance) {
				$(instance.element).attr('data-init', 'true')
			}
		});
	}


	// ****************************************************
	// *			Bind Events   					      *
	// ****************************************************
	$addBtn.on('click', function () {
		$dateRangeNum = $dateRangeNum + 1;
		renderView($dateRangeNum);
	});



	// ****************************************************
	// *			Initial Function 					  *
	// ****************************************************
	function init() {
		if ($cardsLength == 0) {
			renderView($dateRangeNum);
		}
		initFields();
	}
	function reset() {
		$dateRangeNum = 1;
		$cardsLength = 0;
		disabledDates = [];
		init();
	}



	// ****************************************************
	// *			Return APIS     					  *
	// ****************************************************
	return {
		init: init,
		reset: reset
	}

})();

module.exports = dailyWorkingHours;