module.exports = function () {
   
	$('.custom-lang-select').each(function(index, item) {
        $(item).on('change', function() {
           var langType = $(this).val();
           
           if(langType === 'ar') {
                $('.en-item').hide();
                $('.ar-item').show();
           }

           if(langType === 'en') {
                $('.ar-item').hide();
                $('.en-item').show();
           }
        });
    });



     if($('.unitSelect').children('option:selected').attr('data-type') === "project") {
          $('.deliveryTimeSelect').removeClass('d-none');
          $('.deliveryTimeSelect').find('input').removeAttr('disabled');
     }
     $('.unitSelect').on('change', function() {
          if($(this).children('option:selected').attr('data-type') === "project") {
               $('.deliveryTimeSelect').removeClass('d-none');
               $('.deliveryTimeSelect').find('input').removeAttr('disabled');
               console.log('yes')
          } else {
               $(".deliveryTimeSelect").addClass('d-none');
               $('.deliveryTimeSelect').find('input').attr('disabled', 'disabled');
               console.log('no')
          }
     });
};