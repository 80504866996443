module.exports = function () {
	// caching dom
	var $tagGroupForm = $('#tagGroupForm');
	var $assignToServiceContainer = $tagGroupForm.find('#addTagGroup_assignToServiceContainer');
	var $assignToCategoryContainer = $tagGroupForm.find('#addTagGroup_assignToCategoryContainer');
	var $assignToServiceInput = $assignToServiceContainer.find('input');
	var $assignToCategoryInput = $assignToCategoryContainer.find('input');
	$assignToServiceInput.on('change', function() {
		if ($(this).attr('id') === 'addTagGroup_assignToServiceYes'){
			var $target = $(this).parent().parent().parent().siblings();
			if($target.hasClass('d-none')){
				$target.removeClass('d-none').animateCss('fadeIn faster');
				$target.find('input[type="radio"]').prop('required', true);
			}
		}
		if ($(this).attr('id') === 'addTagGroup_assignToServiceNo'){
			var $target = $(this).parent().parent().parent().siblings();
			if(!$target.hasClass('d-none')){
				$target.animateCss('fadeOut faster', function() {
					$target.find('input[type="radio"]:checked').prop('checked', false);
					$target.find('input[type="radio"]:required').prop('required', false);
					$target.find('input[type="radio"]').val('');
					$target.addClass('d-none');
				});
			}
		}
	});
	$assignToCategoryInput.on('change', function() {
		if ($(this).attr('id') === 'addTagGroup_assignToCategoryYes') {
			var $target = $(this).parent().parent().parent().siblings();
			if($target.hasClass('d-none')){
				$target.removeClass('d-none').animateCss('fadeIn faster');
				$target.find('input[type="radio"]').prop('required', true);
			}
		}
		if ($(this).attr('id') === 'addTagGroup_assignToCategoryNo') {
			var $target = $(this).parent().parent().parent().siblings();
			if(!$target.hasClass('d-none')){
				$target.animateCss('fadeOut faster', function() {
					$target.find('input[type="radio"]:checked').prop('checked', false);
					$target.find('input[type="radio"]:required').prop('required', false);
					$target.find('input[type="radio"]').val('');
					$target.addClass('d-none');
				});
			}
		}
	});
}