const split = require('lodash/split');
const trim = require('lodash/trim');

module.exports = function () {
	$('input.custom-file-input[type="file"]').not('input[data-maxFiles]').each(function (index, item) {
		$(item).on('change', function (e) {
			//get the file name
			var $label = $(item).parent().find('.custom-file-label');
			if ($label.length) {
				//replace the "Choose a file" label
				$label.html(e.target.files[0].name);
			}
		})
	});
};