//
// ─── LOADING DEPENDENCIES ───────────────────────────────────────────────────────
//

require('./jquery.chained.js');


//
// ─── EXPORTING MODULE FUNCTION ──────────────────────────────────────────────────
//

module.exports = function () {

	//
	// ─── CACHING DOM ────────────────────────────────────────────────────────────────
	//

	var $chainedSelect = $('select[data-target]');



	$chainedSelect.each(function (index, item) {
		if ($(`select[id="${$(item).data('target')}"]`).length) {
			$(`select[id="${$(item).data('target')}"]`).chained($(item));
		}
	});
}