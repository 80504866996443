require('tinymce');
require('tinymce/themes/modern/theme.min.js');
require('tinymce/plugins/link/plugin.min.js');
require('tinymce/plugins/image/plugin.min.js');
require('tinymce/plugins/textpattern/plugin.min.js');
require('tinymce/plugins/lists/plugin.min.js');

module.exports = function () {
	tinymce.init({
		/* replace textarea having class .tinymce with tinymce editor */
		selector: "textarea.editable",
		mode: "textareas",

		/* theme of the editor */
		theme: "modern",
		skin: "lightgray",

		/* width and height of the editor */
		width: "100%",
		height: 200,

		/* display statusbar */
		statubar: true,

		/* plugin */
		plugins: [
			"link",
			"image",
			"textpattern",
			"lists"
		],

		/* toolbar */
		toolbar: "bold italic | formatselect | style-p style-h1 style-h2 style-h3 style-pre style-code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",

		/* style */
		style_formats: [{
				title: "Headers",
				items: [{
						title: "Header 1",
						format: "h1"
					},
					{
						title: "Header 2",
						format: "h2"
					},
					{
						title: "Header 3",
						format: "h3"
					},
					{
						title: "Header 4",
						format: "h4"
					},
					{
						title: "Header 5",
						format: "h5"
					},
					{
						title: "Header 6",
						format: "h6"
					}
				]
			},
			{
				title: "Inline",
				items: [{
						title: "Bold",
						icon: "bold",
						format: "bold"
					},
					{
						title: "Italic",
						icon: "italic",
						format: "italic"
					},
					{
						title: "Underline",
						icon: "underline",
						format: "underline"
					},
					{
						title: "Strikethrough",
						icon: "strikethrough",
						format: "strikethrough"
					},
					{
						title: "Superscript",
						icon: "superscript",
						format: "superscript"
					},
					{
						title: "Subscript",
						icon: "subscript",
						format: "subscript"
					},
					{
						title: "Code",
						icon: "code",
						format: "code"
					}
				]
			},
			{
				title: "Blocks",
				items: [{
						title: "Paragraph",
						format: "p"
					},
					{
						title: "Blockquote",
						format: "blockquote"
					},
					{
						title: "Div",
						format: "div"
					},
					{
						title: "Pre",
						format: "pre"
					}
				]
			},
			{
				title: "Alignment",
				items: [{
						title: "Left",
						icon: "alignleft",
						format: "alignleft"
					},
					{
						title: "Center",
						icon: "aligncenter",
						format: "aligncenter"
					},
					{
						title: "Right",
						icon: "alignright",
						format: "alignright"
					},
					{
						title: "Justify",
						icon: "alignjustify",
						format: "alignjustify"
					}
				]
			}
		]
	});
}