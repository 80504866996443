var black = "#000000",
	dark = "#2C2C2C",
	white = "#ffffff",
	gray = "#bebebe",
	blue = "#43A8DE",
	blueDark = "#2490ca",
	indigo = "#6610f2",
	purple = "#6f42c1",
	pink = "#e83e8c",
	red = "#DE4343",
	orange = "#FAA634",
	yellow = "#CCCC04",
	green = "#0A9515",
	teal = "#20c997",
	cyan = "#24DAE2",
	brown = "#362C2C",
	facebook = "#3b5998",
	twitter = "#1DA1F2",
	google = "#ea4335",
	instagram = "#517fa4",
	linkedIn = "#0077b5",
	grays = {
		"100": "rgba(0,0,0, 0.1)",
		"200": "rgba(0,0,0, 0.2)",
		"300": "rgba(0,0,0, 0.3)",
		"400": "rgba(0,0,0, 0.4)",
		"500": "rgba(0,0,0, 0.5)",
		"600": "rgba(0,0,0, 0.6)",
		"700": "rgba(0,0,0, 0.7)",
		"800": "rgba(0,0,0, 0.8)",
		"900": "rgba(0,0,0, 0.9)",
	};


module.exports = {
	"black": black,
	"dark": dark,
	"white": white,
	"gray": gray,
	"grayLight": grays['600'],
	"blue": blue,
	"blueDark": blueDark,
	"indigo": indigo,
	"purple": purple,
	"pink": pink,
	"red": red,
	"orange": orange,
	"yellow": yellow,
	"green": green,
	"teal": teal,
	"cyan": cyan,
	"brown": brown,
	"facebook": facebook,
	"twitter": twitter,
	"google": google,
	"instagram": instagram,
	"linked-in": linkedIn,
	"grays": grays,
	"primary": blue,
	"secondary": grays["600"],
	"success": green,
	"info": cyan,
	"warning": yellow,
	"danger": red,
	"light": grays["100"],
	"transparent": "transparent"
};