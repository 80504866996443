const Arabic = require('flatpickr/dist/l10n/ar.js').default.ar;
const English = require('flatpickr/dist/l10n/default.js');


module.exports = function () {

	var dateFormat = "Y-m-d";
	var timeFormat = "h:i K";
	var $filterContainer = $('#InnerFilterCard');
	var $addedDateFrom = $filterContainer.find('#serviceFilter_addedDateFrom');
	var $addedDateTo = $filterContainer.find('#serviceFilter_addedDateTo');
	var $workingHoursFrom = $filterContainer.find('#serviceFilter_workingHoursFrom');
	var $workingHoursTo = $filterContainer.find('#serviceFilter_workingHoursTo');
	var $availabilityFrom = $filterContainer.find('#serviceFilter_availabilityFrom');
	var $availabilityTo = $filterContainer.find('#serviceFilter_availabilityTo');


	$addedDateFrom.flatpickr({
		enableTime: false,
		"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
		dateFormat: dateFormat
	});
	$addedDateTo.flatpickr({
		enableTime: false,
		"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
		dateFormat: dateFormat
	});
	$availabilityFrom.flatpickr({
		enableTime: false,
		"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
		dateFormat: dateFormat,
		defaultDate: new Date().fp_incr(1)
	});
	$availabilityTo.flatpickr({
		enableTime: false,
		"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
		dateFormat: dateFormat,
		defaultDate: new Date().fp_incr(2)
	});
	$workingHoursFrom.flatpickr({
		enableTime: true,
		noCalendar: true,
		dateFormat: timeFormat,
		"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
	});

	$workingHoursTo.flatpickr({
		enableTime: true,
		noCalendar: true,
		dateFormat: timeFormat,
		"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
	});

}