module.exports = () => {
	const workingHours = {
		init: function() {
			this.cacheDom();
			this.eventBinding();
			this.renderView();
		},
		cacheDom: function () {
			this.$weeklyHourContainer = $('.form-weekly-hours-inputs');
			this.$switches = this.$weeklyHourContainer.find('.material-switch-input input');
		},
		eventBinding: function() {
			this.$switches.on('change', this.switch.bind(this));
		},
		switch: function(e){
			this.renderView();
		},
		renderView: function() {
			this.$switches.each(function(index, item){
				var $singleSwitch = $(item);
				var $singleSwitchTarget = $(document).find(`div[data-switch-target="${$singleSwitch.attr('id')}"]`);
				if($singleSwitch.is(':checked')){
					if($singleSwitchTarget.hasClass('invisible')){
						$singleSwitchTarget.removeClass('invisible').animateCss('animated fadeIn');
						$singleSwitchTarget.find('input').prop('required', true).prop('readonly', false);
					}
				} else {
					if(!$singleSwitchTarget.hasClass('invisible')){
						$singleSwitchTarget.addClass('invisible');
						$singleSwitchTarget.find('input').val('').prop('required', false).prop('readonly', true);
					}
				}
				$singleSwitch.on('change', function() {
					if ($singleSwitch.is(':checked')) {
						if ($singleSwitchTarget.hasClass('invisible')) {
							$singleSwitchTarget.removeClass('invisible').animateCss('animated fadeIn');
							$singleSwitchTarget.find('input').prop('required', true).prop('readonly', false);
						}
					} else {
						if (!$singleSwitchTarget.hasClass('invisible')) {
							$singleSwitchTarget.addClass('invisible');
							$singleSwitchTarget.find('input').val('').prop('required', false).prop('readonly', true);
						}
					}
				});
			});
		}
	};
	workingHours.init();
};