//
// ─── EXPORTING MODULE FUNCTION ──────────────────────────────────────────────────
//

module.exports = function () {
	$('.needs-validation').each(function (index, form) {
		var $form = $(form);
		var $inputs = $form.find('input.form-float-control, input.custom-file-input, select');
		$form.on('submit', function (e) {
			if (form.checkValidity() === false) {
				$inputs.each(function(index, input) {
					if($(input).is(':invalid')){
						console.log($(input));
					}
				});
				e.preventDefault();
				e.stopPropagation();
			}
			$form.addClass('was-validated');
		});
		$(window).keydown(function (event) {
			if ((event.keyCode == 13) && (form.checkValidity() === false)) {
				event.preventDefault();
				return false;
			}
		});
		$inputs.each(function (index, input) {
			var $input = $(input);
			$input.on('keyup', function () {
				if ($(this).hasClass('is-invalid') & $(this).is(':valid')) {
					$(this).removeClass('is-invalid').addClass('is-valid');
				}
				if ($(this).hasClass('is-valid') & $(this).is(':invalid')) {
					$(this).removeClass('is-valid').addClass('is-invalid');
				}
			});
		});
	});
};
