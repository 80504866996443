module.exports = function() {
    var t;
    t = function(t, e, i, n) {
        "use strict";
        for (var a = [
                ["Afghanistan (‫افغانستان‬‎)", "af", "93"],
                ["Albania (Shqipëri)", "al", "355"],
                ["Algeria (‫الجزائر‬‎)", "dz", "213"],
                ["American Samoa", "as", "1684"],
                ["Andorra", "ad", "376"],
                ["Angola", "ao", "244"],
                ["Anguilla", "ai", "1264"],
                ["Antigua and Barbuda", "ag", "1268"],
                ["Argentina", "ar", "54"],
                ["Armenia (Հայաստան)", "am", "374"],
                ["Aruba", "aw", "297"],
                ["Australia", "au", "61", 0],
                ["Austria (Österreich)", "at", "43"],
                ["Azerbaijan (Azərbaycan)", "az", "994"],
                ["Bahamas", "bs", "1242"],
                ["Bahrain (‫البحرين‬‎)", "bh", "973"],
                ["Bangladesh (বাংলাদেশ)", "bd", "880"],
                ["Barbados", "bb", "1246"],
                ["Belarus (Беларусь)", "by", "375"],
                ["Belgium (België)", "be", "32"],
                ["Belize", "bz", "501"],
                ["Benin (Bénin)", "bj", "229"],
                ["Bermuda", "bm", "1441"],
                ["Bhutan (འབྲུག)", "bt", "975"],
                ["Bolivia", "bo", "591"],
                ["Bosnia and Herzegovina (Босна и Херцеговина)", "ba", "387"],
                ["Botswana", "bw", "267"],
                ["Brazil (Brasil)", "br", "55"],
                ["British Indian Ocean Territory", "io", "246"],
                ["British Virgin Islands", "vg", "1284"],
                ["Brunei", "bn", "673"],
                ["Bulgaria (България)", "bg", "359"],
                ["Burkina Faso", "bf", "226"],
                ["Burundi (Uburundi)", "bi", "257"],
                ["Cambodia (កម្ពុជា)", "kh", "855"],
                ["Cameroon (Cameroun)", "cm", "237"],
                ["Canada", "ca", "1", 1, ["204", "226", "236", "249", "250", "289", "306", "343", "365", "387", "403", "416", "418", "431", "437", "438", "450", "506", "514", "519", "548", "579", "581", "587", "604", "613", "639", "647", "672", "705", "709", "742", "778", "780", "782", "807", "819", "825", "867", "873", "902", "905"]],
                ["Cape Verde (Kabu Verdi)", "cv", "238"],
                ["Caribbean Netherlands", "bq", "599", 1],
                ["Cayman Islands", "ky", "1345"],
                ["Central African Republic (République centrafricaine)", "cf", "236"],
                ["Chad (Tchad)", "td", "235"],
                ["Chile", "cl", "56"],
                ["China (中国)", "cn", "86"],
                ["Christmas Island", "cx", "61", 2],
                ["Cocos (Keeling) Islands", "cc", "61", 1],
                ["Colombia", "co", "57"],
                ["Comoros (‫جزر القمر‬‎)", "km", "269"],
                ["Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)", "cd", "243"],
                ["Congo (Republic) (Congo-Brazzaville)", "cg", "242"],
                ["Cook Islands", "ck", "682"],
                ["Costa Rica", "cr", "506"],
                ["Côte d’Ivoire", "ci", "225"],
                ["Croatia (Hrvatska)", "hr", "385"],
                ["Cuba", "cu", "53"],
                ["Curaçao", "cw", "599", 0],
                ["Cyprus (Κύπρος)", "cy", "357"],
                ["Czech Republic (Česká republika)", "cz", "420"],
                ["Denmark (Danmark)", "dk", "45"],
                ["Djibouti", "dj", "253"],
                ["Dominica", "dm", "1767"],
                ["Dominican Republic (República Dominicana)", "do", "1", 2, ["809", "829", "849"]],
                ["Ecuador", "ec", "593"],
                ["Egypt (‫مصر‬‎)", "eg", "20"],
                ["El Salvador", "sv", "503"],
                ["Equatorial Guinea (Guinea Ecuatorial)", "gq", "240"],
                ["Eritrea", "er", "291"],
                ["Estonia (Eesti)", "ee", "372"],
                ["Ethiopia", "et", "251"],
                ["Falkland Islands (Islas Malvinas)", "fk", "500"],
                ["Faroe Islands (Føroyar)", "fo", "298"],
                ["Fiji", "fj", "679"],
                ["Finland (Suomi)", "fi", "358", 0],
                ["France", "fr", "33"],
                ["French Guiana (Guyane française)", "gf", "594"],
                ["French Polynesia (Polynésie française)", "pf", "689"],
                ["Gabon", "ga", "241"],
                ["Gambia", "gm", "220"],
                ["Georgia (საქართველო)", "ge", "995"],
                ["Germany (Deutschland)", "de", "49"],
                ["Ghana (Gaana)", "gh", "233"],
                ["Gibraltar", "gi", "350"],
                ["Greece (Ελλάδα)", "gr", "30"],
                ["Greenland (Kalaallit Nunaat)", "gl", "299"],
                ["Grenada", "gd", "1473"],
                ["Guadeloupe", "gp", "590", 0],
                ["Guam", "gu", "1671"],
                ["Guatemala", "gt", "502"],
                ["Guernsey", "gg", "44", 1],
                ["Guinea (Guinée)", "gn", "224"],
                ["Guinea-Bissau (Guiné Bissau)", "gw", "245"],
                ["Guyana", "gy", "592"],
                ["Haiti", "ht", "509"],
                ["Honduras", "hn", "504"],
                ["Hong Kong (香港)", "hk", "852"],
                ["Hungary (Magyarország)", "hu", "36"],
                ["Iceland (Ísland)", "is", "354"],
                ["India (भारत)", "in", "91"],
                ["Indonesia", "id", "62"],
                ["Iran (‫ایران‬‎)", "ir", "98"],
                ["Iraq (‫العراق‬‎)", "iq", "964"],
                ["Ireland", "ie", "353"],
                ["Isle of Man", "im", "44", 2],
                ["Israel (‫ישראל‬‎)", "il", "972"],
                ["Italy (Italia)", "it", "39", 0],
                ["Jamaica", "jm", "1", 4, ["876", "658"]],
                ["Japan (日本)", "jp", "81"],
                ["Jersey", "je", "44", 3],
                ["Jordan (‫الأردن‬‎)", "jo", "962"],
                ["Kazakhstan (Казахстан)", "kz", "7", 1],
                ["Kenya", "ke", "254"],
                ["Kiribati", "ki", "686"],
                ["Kosovo", "xk", "383"],
                ["Kuwait (‫الكويت‬‎)", "kw", "965"],
                ["Kyrgyzstan (Кыргызстан)", "kg", "996"],
                ["Laos (ລາວ)", "la", "856"],
                ["Latvia (Latvija)", "lv", "371"],
                ["Lebanon (‫لبنان‬‎)", "lb", "961"],
                ["Lesotho", "ls", "266"],
                ["Liberia", "lr", "231"],
                ["Libya (‫ليبيا‬‎)", "ly", "218"],
                ["Liechtenstein", "li", "423"],
                ["Lithuania (Lietuva)", "lt", "370"],
                ["Luxembourg", "lu", "352"],
                ["Macau (澳門)", "mo", "853"],
                ["Macedonia (FYROM) (Македонија)", "mk", "389"],
                ["Madagascar (Madagasikara)", "mg", "261"],
                ["Malawi", "mw", "265"],
                ["Malaysia", "my", "60"],
                ["Maldives", "mv", "960"],
                ["Mali", "ml", "223"],
                ["Malta", "mt", "356"],
                ["Marshall Islands", "mh", "692"],
                ["Martinique", "mq", "596"],
                ["Mauritania (‫موريتانيا‬‎)", "mr", "222"],
                ["Mauritius (Moris)", "mu", "230"],
                ["Mayotte", "yt", "262", 1],
                ["Mexico (México)", "mx", "52"],
                ["Micronesia", "fm", "691"],
                ["Moldova (Republica Moldova)", "md", "373"],
                ["Monaco", "mc", "377"],
                ["Mongolia (Монгол)", "mn", "976"],
                ["Montenegro (Crna Gora)", "me", "382"],
                ["Montserrat", "ms", "1664"],
                ["Morocco (‫المغرب‬‎)", "ma", "212", 0],
                ["Mozambique (Moçambique)", "mz", "258"],
                ["Myanmar (Burma) (မြန်မာ)", "mm", "95"],
                ["Namibia (Namibië)", "na", "264"],
                ["Nauru", "nr", "674"],
                ["Nepal (नेपाल)", "np", "977"],
                ["Netherlands (Nederland)", "nl", "31"],
                ["New Caledonia (Nouvelle-Calédonie)", "nc", "687"],
                ["New Zealand", "nz", "64"],
                ["Nicaragua", "ni", "505"],
                ["Niger (Nijar)", "ne", "227"],
                ["Nigeria", "ng", "234"],
                ["Niue", "nu", "683"],
                ["Norfolk Island", "nf", "672"],
                ["North Korea (조선 민주주의 인민 공화국)", "kp", "850"],
                ["Northern Mariana Islands", "mp", "1670"],
                ["Norway (Norge)", "no", "47", 0],
                ["Oman (‫عُمان‬‎)", "om", "968"],
                ["Pakistan (‫پاکستان‬‎)", "pk", "92"],
                ["Palau", "pw", "680"],
                ["Palestine (‫فلسطين‬‎)", "ps", "970"],
                ["Panama (Panamá)", "pa", "507"],
                ["Papua New Guinea", "pg", "675"],
                ["Paraguay", "py", "595"],
                ["Peru (Perú)", "pe", "51"],
                ["Philippines", "ph", "63"],
                ["Poland (Polska)", "pl", "48"],
                ["Portugal", "pt", "351"],
                ["Puerto Rico", "pr", "1", 3, ["787", "939"]],
                ["Qatar (‫قطر‬‎)", "qa", "974"],
                ["Réunion (La Réunion)", "re", "262", 0],
                ["Romania (România)", "ro", "40"],
                ["Russia (Россия)", "ru", "7", 0],
                ["Rwanda", "rw", "250"],
                ["Saint Barthélemy", "bl", "590", 1],
                ["Saint Helena", "sh", "290"],
                ["Saint Kitts and Nevis", "kn", "1869"],
                ["Saint Lucia", "lc", "1758"],
                ["Saint Martin (Saint-Martin (partie française))", "mf", "590", 2],
                ["Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)", "pm", "508"],
                ["Saint Vincent and the Grenadines", "vc", "1784"],
                ["Samoa", "ws", "685"],
                ["San Marino", "sm", "378"],
                ["São Tomé and Príncipe (São Tomé e Príncipe)", "st", "239"],
                ["Saudi Arabia (‫المملكة العربية السعودية‬‎)", "sa", "966"],
                ["Senegal (Sénégal)", "sn", "221"],
                ["Serbia (Србија)", "rs", "381"],
                ["Seychelles", "sc", "248"],
                ["Sierra Leone", "sl", "232"],
                ["Singapore", "sg", "65"],
                ["Sint Maarten", "sx", "1721"],
                ["Slovakia (Slovensko)", "sk", "421"],
                ["Slovenia (Slovenija)", "si", "386"],
                ["Solomon Islands", "sb", "677"],
                ["Somalia (Soomaaliya)", "so", "252"],
                ["South Africa", "za", "27"],
                ["South Korea (대한민국)", "kr", "82"],
                ["South Sudan (‫جنوب السودان‬‎)", "ss", "211"],
                ["Spain (España)", "es", "34"],
                ["Sri Lanka (ශ්‍රී ලංකාව)", "lk", "94"],
                ["Sudan (‫السودان‬‎)", "sd", "249"],
                ["Suriname", "sr", "597"],
                ["Svalbard and Jan Mayen", "sj", "47", 1],
                ["Swaziland", "sz", "268"],
                ["Sweden (Sverige)", "se", "46"],
                ["Switzerland (Schweiz)", "ch", "41"],
                ["Syria (‫سوريا‬‎)", "sy", "963"],
                ["Taiwan (台灣)", "tw", "886"],
                ["Tajikistan", "tj", "992"],
                ["Tanzania", "tz", "255"],
                ["Thailand (ไทย)", "th", "66"],
                ["Timor-Leste", "tl", "670"],
                ["Togo", "tg", "228"],
                ["Tokelau", "tk", "690"],
                ["Tonga", "to", "676"],
                ["Trinidad and Tobago", "tt", "1868"],
                ["Tunisia (‫تونس‬‎)", "tn", "216"],
                ["Turkey (Türkiye)", "tr", "90"],
                ["Turkmenistan", "tm", "993"],
                ["Turks and Caicos Islands", "tc", "1649"],
                ["Tuvalu", "tv", "688"],
                ["U.S. Virgin Islands", "vi", "1340"],
                ["Uganda", "ug", "256"],
                ["Ukraine (Україна)", "ua", "380"],
                ["United Arab Emirates (‫الإمارات العربية المتحدة‬‎)", "ae", "971"],
                ["United Kingdom", "gb", "44", 0],
                ["United States", "us", "1", 0],
                ["Uruguay", "uy", "598"],
                ["Uzbekistan (Oʻzbekiston)", "uz", "998"],
                ["Vanuatu", "vu", "678"],
                ["Vatican City (Città del Vaticano)", "va", "39", 1],
                ["Venezuela", "ve", "58"],
                ["Vietnam (Việt Nam)", "vn", "84"],
                ["Wallis and Futuna (Wallis-et-Futuna)", "wf", "681"],
                ["Western Sahara (‫الصحراء الغربية‬‎)", "eh", "212", 1],
                ["Yemen (‫اليمن‬‎)", "ye", "967"],
                ["Zambia", "zm", "260"],
                ["Zimbabwe", "zw", "263"],
                ["Åland Islands", "ax", "358", 1]
            ], s = 0; s < a.length; s++) {
            var o = a[s];
            a[s] = {
                name: o[0],
                iso2: o[1],
                dialCode: o[2],
                priority: o[3] || 0,
                areaCodes: o[4] || null
            }
        }

        function r(t, e) {
            for (var i = 0; i < e.length; i++) {
                var n = e[i];
                n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n)
            }
        }
        e.intlTelInputGlobals = {
            getInstance: function(t) {
                var i = t.getAttribute("data-intl-tel-input-id");
                return e.intlTelInputGlobals.instances[i]
            },
            instances: {}
        };
        var l = 0,
            u = {
                allowDropdown: !0,
                autoHideDialCode: !0,
                autoPlaceholder: "polite",
                customContainer: "",
                customPlaceholder: null,
                dropdownContainer: null,
                excludeCountries: [],
                formatOnDisplay: !0,
                geoIpLookup: null,
                hiddenInput: "",
                initialCountry: "",
                localizedCountries: null,
                nationalMode: !0,
                onlyCountries: [],
                placeholderNumberType: "MOBILE",
                preferredCountries: ["EG"],
                separateDialCode: !1,
                utilsScript: ""
            },
            d = ["800", "822", "833", "844", "855", "866", "877", "880", "881", "882", "883", "884", "885", "886", "887", "888", "889"];
        e.addEventListener("load", function() {
            e.intlTelInputGlobals.windowLoaded = !0
        });
        var h = function(t, e) {
                for (var i = Object.keys(t), n = 0; n < i.length; n++) e(i[n], t[i[n]])
            },
            c = function(t) {
                h(e.intlTelInputGlobals.instances, function(i) {
                    e.intlTelInputGlobals.instances[i][t]()
                })
            },
            p = function() {
                function t(e, i) {
                    var n = this;
                    ! function(t, e) {
                        if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function")
                    }(this, t), this.id = l++, this.telInput = e, this.activeItem = null, this.highlightedItem = null;
                    var a = i || {};
                    this.options = {}, h(u, function(t, e) {
                        n.options[t] = a.hasOwnProperty(t) ? a[t] : e
                    }), this.hadInitialPlaceholder = Boolean(e.getAttribute("placeholder"))
                }
                var n, s, o;
                return n = t, (s = [{
                    key: "_init",
                    value: function() {
                        var t = this;
                        if (this.options.nationalMode && (this.options.autoHideDialCode = !1), this.options.separateDialCode && (this.options.autoHideDialCode = this.options.nationalMode = !1), this.isMobile = /Android.+Mobile|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent), this.isMobile && (i.body.classList.add("iti-mobile"), this.options.dropdownContainer || (this.options.dropdownContainer = i.body)), "undefined" != typeof Promise) {
                            var e = new Promise(function(e, i) {
                                    t.resolveAutoCountryPromise = e, t.rejectAutoCountryPromise = i
                                }),
                                n = new Promise(function(e, i) {
                                    t.resolveUtilsScriptPromise = e, t.rejectUtilsScriptPromise = i
                                });
                            this.promise = Promise.all([e, n])
                        } else this.resolveAutoCountryPromise = this.rejectAutoCountryPromise = function() {}, this.resolveUtilsScriptPromise = this.rejectUtilsScriptPromise = function() {};
                        this.selectedCountryData = {}, this._processCountryData(), this._generateMarkup(), this._setInitialState(), this._initListeners(), this._initRequests()
                    }
                }, {
                    key: "_processCountryData",
                    value: function() {
                        this._processAllCountries(), this._processCountryCodes(), this._processPreferredCountries(), this.options.localizedCountries && this._translateCountriesByLocale(), (this.options.onlyCountries.length || this.options.localizedCountries) && this.countries.sort(this._countryNameSort)
                    }
                }, {
                    key: "_addCountryCode",
                    value: function(t, e, i) {
                        this.countryCodes.hasOwnProperty(e) || (this.countryCodes[e] = []);
                        var n = i || 0;
                        this.countryCodes[e][n] = t
                    }
                }, {
                    key: "_processAllCountries",
                    value: function() {
                        if (this.options.onlyCountries.length) {
                            var t = this.options.onlyCountries.map(function(t) {
                                return t.toLowerCase()
                            });
                            this.countries = a.filter(function(e) {
                                return t.indexOf(e.iso2) > -1
                            })
                        } else if (this.options.excludeCountries.length) {
                            var e = this.options.excludeCountries.map(function(t) {
                                return t.toLowerCase()
                            });
                            this.countries = a.filter(function(t) {
                                return -1 === e.indexOf(t.iso2)
                            })
                        } else this.countries = a
                    }
                }, {
                    key: "_translateCountriesByLocale",
                    value: function() {
                        for (var t = 0; t < this.countries.length; t++) {
                            var e = this.countries[t].iso2.toLowerCase();
                            this.options.localizedCountries.hasOwnProperty(e) && (this.countries[t].name = this.options.localizedCountries[e])
                        }
                    }
                }, {
                    key: "_countryNameSort",
                    value: function(t, e) {
                        return t.name.localeCompare(e.name)
                    }
                }, {
                    key: "_processCountryCodes",
                    value: function() {
                        this.countryCodes = {};
                        for (var t = 0; t < this.countries.length; t++) {
                            var e = this.countries[t];
                            if (this._addCountryCode(e.iso2, e.dialCode, e.priority), e.areaCodes)
                                for (var i = 0; i < e.areaCodes.length; i++) this._addCountryCode(e.iso2, e.dialCode + e.areaCodes[i])
                        }
                    }
                }, {
                    key: "_processPreferredCountries",
                    value: function() {
                        this.preferredCountries = [];
                        for (var t = 0; t < this.options.preferredCountries.length; t++) {
                            var e = this.options.preferredCountries[t].toLowerCase(),
                                i = this._getCountryData(e, !1, !0);
                            i && this.preferredCountries.push(i)
                        }
                    }
                }, {
                    key: "_createEl",
                    value: function(t, e, n) {
                        var a = i.createElement(t);
                        return e && h(e, function(t, e) {
                            return a.setAttribute(t, e)
                        }), n && n.appendChild(a), a
                    }
                }, {
                    key: "_generateMarkup",
                    value: function() {
                        this.telInput.setAttribute("autocomplete", "off");
                        var t = "intl-tel-input";
                        this.options.allowDropdown && (t += " allow-dropdown"), this.options.separateDialCode && (t += " separate-dial-code"), this.options.customContainer && (t += " ", t += this.options.customContainer);
                        var e = this._createEl("div", {
                            class: t
                        });
                        if (this.telInput.parentNode.insertBefore(e, this.telInput), this.flagsContainer = this._createEl("div", {
                                class: "flag-container"
                            }, e), e.appendChild(this.telInput), this.selectedFlag = this._createEl("div", {
                                class: "selected-flag",
                                role: "combobox",
                                "aria-owns": "country-listbox"
                            }, this.flagsContainer), this.selectedFlagInner = this._createEl("div", {
                                class: "iti-flag"
                            }, this.selectedFlag), this.options.separateDialCode && (this.selectedDialCode = this._createEl("div", {
                                class: "selected-dial-code"
                            }, this.selectedFlag)), this.options.allowDropdown && (this.selectedFlag.setAttribute("tabindex", "0"), this.dropdownArrow = this._createEl("div", {
                                class: "iti-arrow"
                            }, this.selectedFlag), this.countryList = this._createEl("ul", {
                                class: "country-list hide",
                                id: "country-listbox",
                                "aria-expanded": "false",
                                role: "listbox"
                            }), this.preferredCountries.length && (this._appendListItems(this.preferredCountries, "preferred"), this._createEl("li", {
                                class: "divider",
                                role: "separator",
                                "aria-disabled": "true"
                            }, this.countryList)), this._appendListItems(this.countries, "standard"), this.options.dropdownContainer ? (this.dropdown = this._createEl("div", {
                                class: "intl-tel-input iti-container"
                            }), this.dropdown.appendChild(this.countryList)) : this.flagsContainer.appendChild(this.countryList)), this.options.hiddenInput) {
                            var i = this.options.hiddenInput,
                                n = this.telInput.getAttribute("name");
                            if (n) {
                                var a = n.lastIndexOf("["); - 1 !== a && (i = "".concat(n.substr(0, a), "[").concat(i, "]"))
                            }
                            this.hiddenInput = this._createEl("input", {
                                type: "hidden",
                                name: i
                            }), e.appendChild(this.hiddenInput)
                        }
                    }
                }, {
                    key: "_appendListItems",
                    value: function(t, e) {
                        for (var i = "", n = 0; n < t.length; n++) {
                            var a = t[n];
                            i += "<li class='country ".concat(e, "' id='iti-item-").concat(a.iso2, "' role='option' data-dial-code='").concat(a.dialCode, "' data-country-code='").concat(a.iso2, "'>"), i += "<div class='flag-box'><div class='iti-flag ".concat(a.iso2, "'></div></div>"), i += "<span class='country-name'>".concat(a.name, "</span>"), i += "<span class='dial-code'>+".concat(a.dialCode, "</span>"), i += "</li>"
                        }
                        this.countryList.insertAdjacentHTML("beforeend", i)
                    }
                }, {
                    key: "_setInitialState",
                    value: function() {
                        var t = this.telInput.value,
                            e = this._getDialCode(t),
                            i = this._isRegionlessNanp(t),
                            n = this.options,
                            a = n.initialCountry,
                            s = n.nationalMode,
                            o = n.autoHideDialCode,
                            r = n.separateDialCode;
                        e && !i ? this._updateFlagFromNumber(t) : "auto" !== a && (a ? this._setFlag(a.toLowerCase()) : e && i ? this._setFlag("us") : (this.defaultCountry = this.preferredCountries.length ? this.preferredCountries[0].iso2 : this.countries[0].iso2, t || this._setFlag(this.defaultCountry)), t || s || o || r || (this.telInput.value = "+".concat(this.selectedCountryData.dialCode))), t && this._updateValFromNumber(t)
                    }
                }, {
                    key: "_initListeners",
                    value: function() {
                        this._initKeyListeners(), this.options.autoHideDialCode && this._initFocusListeners(), this.options.allowDropdown && this._initDropdownListeners(), this.hiddenInput && this._initHiddenInputListener()
                    }
                }, {
                    key: "_initHiddenInputListener",
                    value: function() {
                        var t = this;
                        this._handleHiddenInputSubmit = function() {
                            t.hiddenInput.value = t.getNumber()
                        }, this.telInput.form && this.telInput.form.addEventListener("submit", this._handleHiddenInputSubmit)
                    }
                }, {
                    key: "_getClosestLabel",
                    value: function() {
                        for (var t = this.telInput; t && "LABEL" !== t.tagName;) t = t.parentNode;
                        return t
                    }
                }, {
                    key: "_initDropdownListeners",
                    value: function() {
                        var t = this;
                        this._handleLabelClick = function(e) {
                            t.countryList.classList.contains("hide") ? t.telInput.focus() : e.preventDefault()
                        };
                        var e = this._getClosestLabel();
                        e && e.addEventListener("click", this._handleLabelClick), this._handleClickSelectedFlag = function() {
                            !t.countryList.classList.contains("hide") || t.telInput.disabled || t.telInput.readOnly || t._showDropdown()
                        }, this.selectedFlag.addEventListener("click", this._handleClickSelectedFlag), this._handleFlagsContainerKeydown = function(e) {
                            t.countryList.classList.contains("hide") && -1 !== ["ArrowUp", "ArrowDown", " ", "Enter"].indexOf(e.key) && (e.preventDefault(), e.stopPropagation(), t._showDropdown()), "Tab" === e.key && t._closeDropdown()
                        }, this.flagsContainer.addEventListener("keydown", this._handleFlagsContainerKeydown)
                    }
                }, {
                    key: "_initRequests",
                    value: function() {
                        var t = this;
                        this.options.utilsScript && !e.intlTelInputUtils ? e.intlTelInputGlobals.windowLoaded ? e.intlTelInputGlobals.loadUtils(this.options.utilsScript) : e.addEventListener("load", function() {
                            e.intlTelInputGlobals.loadUtils(t.options.utilsScript)
                        }) : this.resolveUtilsScriptPromise(), "auto" === this.options.initialCountry ? this._loadAutoCountry() : this.resolveAutoCountryPromise()
                    }
                }, {
                    key: "_loadAutoCountry",
                    value: function() {
                        e.intlTelInputGlobals.autoCountry ? this.handleAutoCountry() : e.intlTelInputGlobals.startedLoadingAutoCountry || (e.intlTelInputGlobals.startedLoadingAutoCountry = !0, "function" == typeof this.options.geoIpLookup && this.options.geoIpLookup(function(t) {
                            e.intlTelInputGlobals.autoCountry = t.toLowerCase(), setTimeout(function() {
                                return c("handleAutoCountry")
                            })
                        }, function() {
                            return c("rejectAutoCountryPromise")
                        }))
                    }
                }, {
                    key: "_initKeyListeners",
                    value: function() {
                        var t = this;
                        this._handleKeyupEvent = function() {
                            t._updateFlagFromNumber(t.telInput.value) && t._triggerCountryChange()
                        }, this.telInput.addEventListener("keyup", this._handleKeyupEvent), this._handleClipboardEvent = function() {
                            setTimeout(t._handleKeyupEvent)
                        }, this.telInput.addEventListener("cut", this._handleClipboardEvent), this.telInput.addEventListener("paste", this._handleClipboardEvent)
                    }
                }, {
                    key: "_cap",
                    value: function(t) {
                        var e = this.telInput.getAttribute("maxlength");
                        return e && t.length > e ? t.substr(0, e) : t
                    }
                }, {
                    key: "_initFocusListeners",
                    value: function() {
                        var t = this;
                        this._handleMousedownFocusEvent = function(e) {
                            t.telInput === i.activeElement || t.telInput.value || (e.preventDefault(), t.telInput.focus())
                        }, this.telInput.addEventListener("mousedown", this._handleMousedownFocusEvent), this._handleKeypressPlusEvent = function(e) {
                            "+" === e.key && (t.telInput.value = "")
                        }, this._handleFocusEvent = function() {
                            t.telInput.value || t.telInput.readOnly || !t.selectedCountryData.dialCode || (t.telInput.value = "+".concat(t.selectedCountryData.dialCode), t.telInput.addEventListener("keypress", t._handleKeypressPlusEvent), setTimeout(function() {
                                var e = t.telInput.value.length;
                                t.telInput.setSelectionRange(e, e)
                            }))
                        }, this.telInput.addEventListener("focus", this._handleFocusEvent), this._handleSubmitOrBlurEvent = function() {
                            t._removeEmptyDialCode()
                        }, this.telInput.form && this.telInput.form.addEventListener("submit", this._handleSubmitOrBlurEvent), this.telInput.addEventListener("blur", this._handleSubmitOrBlurEvent)
                    }
                }, {
                    key: "_removeEmptyDialCode",
                    value: function() {
                        if ("+" === this.telInput.value.charAt(0)) {
                            var t = this._getNumeric(this.telInput.value);
                            t && this.selectedCountryData.dialCode !== t || (this.telInput.value = "")
                        }
                        this.telInput.removeEventListener("keypress", this._handleKeypressPlusEvent)
                    }
                }, {
                    key: "_getNumeric",
                    value: function(t) {
                        return t.replace(/\D/g, "")
                    }
                }, {
                    key: "_trigger",
                    value: function(t) {
                        var e = i.createEvent("Event");
                        e.initEvent(t, !0, !0), this.telInput.dispatchEvent(e)
                    }
                }, {
                    key: "_showDropdown",
                    value: function() {
                        this.countryList.classList.remove("hide"), this.countryList.setAttribute("aria-expanded", "true"), this._setDropdownPosition(), this.activeItem && (this._highlightListItem(this.activeItem), this._scrollTo(this.activeItem)), this._bindDropdownListeners(), this.dropdownArrow.classList.add("up"), this._trigger("open:countrydropdown")
                    }
                }, {
                    key: "_toggleClass",
                    value: function(t, e, i) {
                        i && !t.classList.contains(e) ? t.classList.add(e) : !i && t.classList.contains(e) && t.classList.remove(e)
                    }
                }, {
                    key: "_setDropdownPosition",
                    value: function() {
                        var t = this;
                        if (this.options.dropdownContainer && this.options.dropdownContainer.appendChild(this.dropdown), !this.isMobile) {
                            var n = this.telInput.getBoundingClientRect(),
                                a = e.pageYOffset || i.documentElement.scrollTop,
                                s = n.top + a,
                                o = this.countryList.offsetHeight,
                                r = s + this.telInput.offsetHeight + o < a + e.innerHeight,
                                l = s - o > a;
                            if (this._toggleClass(this.countryList, "dropup", !r && l), this.options.dropdownContainer) {
                                var u = !r && l ? 0 : this.telInput.offsetHeight;
                                this.dropdown.style.top = "".concat(s + u, "px"), this.dropdown.style.left = "".concat(n.left + i.body.scrollLeft, "px"), this._handleWindowScroll = function() {
                                    return t._closeDropdown()
                                }, e.addEventListener("scroll", this._handleWindowScroll)
                            }
                        }
                    }
                }, {
                    key: "_getClosestListItem",
                    value: function(t) {
                        for (var e = t; e && e !== this.countryList && !e.classList.contains("country");) e = e.parentNode;
                        return e === this.countryList ? null : e
                    }
                }, {
                    key: "_bindDropdownListeners",
                    value: function() {
                        var t = this;
                        this._handleMouseoverCountryList = function(e) {
                            var i = t._getClosestListItem(e.target);
                            i && t._highlightListItem(i)
                        }, this.countryList.addEventListener("mouseover", this._handleMouseoverCountryList), this._handleClickCountryList = function(e) {
                            var i = t._getClosestListItem(e.target);
                            i && t._selectListItem(i)
                        }, this.countryList.addEventListener("click", this._handleClickCountryList);
                        var e = !0;
                        this._handleClickOffToClose = function() {
                            e || t._closeDropdown(), e = !1
                        }, i.documentElement.addEventListener("click", this._handleClickOffToClose);
                        var n = "",
                            a = null;
                        this._handleKeydownOnDropdown = function(e) {
                            e.preventDefault(), "ArrowUp" === e.key || "ArrowDown" === e.key ? t._handleUpDownKey(e.key) : "Enter" === e.key ? t._handleEnterKey() : "Escape" === e.key ? t._closeDropdown() : /^[a-zA-ZÀ-ÿ ]$/.test(e.key) && (a && clearTimeout(a), n += e.key.toLowerCase(), t._searchForCountry(n), a = setTimeout(function() {
                                n = ""
                            }, 1e3))
                        }, i.addEventListener("keydown", this._handleKeydownOnDropdown)
                    }
                }, {
                    key: "_handleUpDownKey",
                    value: function(t) {
                        var e = "ArrowUp" === t ? this.highlightedItem.previousElementSibling : this.highlightedItem.nextElementSibling;
                        e && (e.classList.contains("divider") && (e = "ArrowUp" === t ? e.previousElementSibling : e.nextElementSibling), this._highlightListItem(e), this._scrollTo(e))
                    }
                }, {
                    key: "_handleEnterKey",
                    value: function() {
                        this.highlightedItem && this._selectListItem(this.highlightedItem)
                    }
                }, {
                    key: "_searchForCountry",
                    value: function(t) {
                        for (var e = 0; e < this.countries.length; e++)
                            if (this._startsWith(this.countries[e].name, t)) {
                                var i = this.countryList.querySelector("#iti-item-".concat(this.countries[e].iso2));
                                this._highlightListItem(i), this._scrollTo(i, !0);
                                break
                            }
                    }
                }, {
                    key: "_startsWith",
                    value: function(t, e) {
                        return t.substr(0, e.length).toLowerCase() === e
                    }
                }, {
                    key: "_updateValFromNumber",
                    value: function(t) {
                        var i = t;
                        if (this.options.formatOnDisplay && e.intlTelInputUtils && this.selectedCountryData) {
                            var n = !this.options.separateDialCode && (this.options.nationalMode || "+" !== i.charAt(0)),
                                a = intlTelInputUtils.numberFormat,
                                s = a.NATIONAL,
                                o = a.INTERNATIONAL,
                                r = n ? s : o;
                            i = intlTelInputUtils.formatNumber(i, this.selectedCountryData.iso2, r)
                        }
                        i = this._beforeSetNumber(i), this.telInput.value = i
                    }
                }, {
                    key: "_updateFlagFromNumber",
                    value: function(t) {
                        var e = t,
                            i = "1" === this.selectedCountryData.dialCode;
                        e && this.options.nationalMode && i && "+" !== e.charAt(0) && ("1" !== e.charAt(0) && (e = "1".concat(e)), e = "+".concat(e));
                        var n = this._getDialCode(e),
                            a = this._getNumeric(e),
                            s = null;
                        if (n) {
                            var o = this.countryCodes[this._getNumeric(n)],
                                r = -1 !== o.indexOf(this.selectedCountryData.iso2),
                                l = "+1" === n && a.length >= 4;
                            if (!("1" === this.selectedCountryData.dialCode && this._isRegionlessNanp(a)) && (!r || l))
                                for (var u = 0; u < o.length; u++)
                                    if (o[u]) {
                                        s = o[u];
                                        break
                                    }
                        } else "+" === e.charAt(0) && a.length ? s = "" : e && "+" !== e || (s = this.defaultCountry);
                        return null !== s && this._setFlag(s)
                    }
                }, {
                    key: "_isRegionlessNanp",
                    value: function(t) {
                        var e = this._getNumeric(t);
                        if ("1" === e.charAt(0)) {
                            var i = e.substr(1, 3);
                            return -1 !== d.indexOf(i)
                        }
                        return !1
                    }
                }, {
                    key: "_highlightListItem",
                    value: function(t) {
                        var e = this.highlightedItem;
                        e && e.classList.remove("highlight"), this.highlightedItem = t, this.highlightedItem.classList.add("highlight")
                    }
                }, {
                    key: "_getCountryData",
                    value: function(t, e, i) {
                        for (var n = e ? a : this.countries, s = 0; s < n.length; s++)
                            if (n[s].iso2 === t) return n[s];
                        if (i) return null;
                        throw new Error("No country data for '".concat(t, "'"))
                    }
                }, {
                    key: "_setFlag",
                    value: function(t) {
                        var e = this.selectedCountryData.iso2 ? this.selectedCountryData : {};
                        this.selectedCountryData = t ? this._getCountryData(t, !1, !1) : {}, this.selectedCountryData.iso2 && (this.defaultCountry = this.selectedCountryData.iso2), this.selectedFlagInner.setAttribute("class", "iti-flag ".concat(t));
                        var i = t ? "".concat(this.selectedCountryData.name, ": +").concat(this.selectedCountryData.dialCode) : "Unknown";
                        if (this.selectedFlag.setAttribute("title", i), this.options.separateDialCode) {
                            var n = this.selectedCountryData.dialCode ? "+".concat(this.selectedCountryData.dialCode) : "";
                            this.selectedDialCode.innerHTML = n, this.telInput.style.paddingLeft = "".concat(this.selectedFlag.offsetWidth + 6, "px")
                        }
                        if (this._updatePlaceholder(), this.options.allowDropdown) {
                            var a = this.activeItem;
                            if (a && (a.classList.remove("active"), a.setAttribute("aria-selected", "false")), t) {
                                var s = this.countryList.querySelector("#iti-item-".concat(t));
                                s.setAttribute("aria-selected", "true"), s.classList.add("active"), this.activeItem = s, this.countryList.setAttribute("aria-activedescendant", s.getAttribute("id"))
                            }
                        }
                        return e.iso2 !== t
                    }
                }, {
                    key: "_updatePlaceholder",
                    value: function() {
                        var t = "aggressive" === this.options.autoPlaceholder || !this.hadInitialPlaceholder && "polite" === this.options.autoPlaceholder;
                        if (e.intlTelInputUtils && t) {
                            var i = intlTelInputUtils.numberType[this.options.placeholderNumberType],
                                n = this.selectedCountryData.iso2 ? intlTelInputUtils.getExampleNumber(this.selectedCountryData.iso2, this.options.nationalMode, i) : "";
                            n = this._beforeSetNumber(n), "function" == typeof this.options.customPlaceholder && (n = this.options.customPlaceholder(n, this.selectedCountryData)), this.telInput.setAttribute("placeholder", n)
                        }
                    }
                }, {
                    key: "_selectListItem",
                    value: function(t) {
                        var e = this._setFlag(t.getAttribute("data-country-code"));
                        this._closeDropdown(), this._updateDialCode(t.getAttribute("data-dial-code"), !0), this.telInput.focus();
                        var i = this.telInput.value.length;
                        this.telInput.setSelectionRange(i, i), e && this._triggerCountryChange()
                    }
                }, {
                    key: "_closeDropdown",
                    value: function() {
                        this.countryList.classList.add("hide"), this.countryList.setAttribute("aria-expanded", "false"), this.dropdownArrow.classList.remove("up"), i.removeEventListener("keydown", this._handleKeydownOnDropdown), i.documentElement.removeEventListener("click", this._handleClickOffToClose), this.countryList.removeEventListener("mouseover", this._handleMouseoverCountryList), this.countryList.removeEventListener("click", this._handleClickCountryList), this.options.dropdownContainer && (this.isMobile || e.removeEventListener("scroll", this._handleWindowScroll), this.dropdown.parentNode && this.dropdown.parentNode.removeChild(this.dropdown)), this._trigger("close:countrydropdown")
                    }
                }, {
                    key: "_scrollTo",
                    value: function(t, n) {
                        var a = this.countryList,
                            s = e.pageYOffset || i.documentElement.scrollTop,
                            o = a.offsetHeight,
                            r = a.getBoundingClientRect().top + s,
                            l = r + o,
                            u = t.offsetHeight,
                            d = t.getBoundingClientRect().top + s,
                            h = d + u,
                            c = d - r + a.scrollTop,
                            p = o / 2 - u / 2;
                        if (d < r) n && (c -= p), a.scrollTop = c;
                        else if (h > l) {
                            n && (c += p);
                            var v = o - u;
                            a.scrollTop = c - v
                        }
                    }
                }, {
                    key: "_updateDialCode",
                    value: function(t, e) {
                        var i, n = this.telInput.value,
                            a = "+".concat(t);
                        if ("+" === n.charAt(0)) {
                            var s = this._getDialCode(n);
                            i = s ? n.replace(s, a) : a
                        } else {
                            if (this.options.nationalMode || this.options.separateDialCode) return;
                            if (n) i = a + n;
                            else {
                                if (!e && this.options.autoHideDialCode) return;
                                i = a
                            }
                        }
                        this.telInput.value = i
                    }
                }, {
                    key: "_getDialCode",
                    value: function(t) {
                        var e = "";
                        if ("+" === t.charAt(0))
                            for (var i = "", n = 0; n < t.length; n++) {
                                var a = t.charAt(n);
                                if (!isNaN(parseInt(a, 10)) && (i += a, this.countryCodes[i] && (e = t.substr(0, n + 1)), 4 === i.length)) break
                            }
                        return e
                    }
                }, {
                    key: "_getFullNumber",
                    value: function() {
                        var t = this.telInput.value.trim(),
                            e = this.selectedCountryData.dialCode,
                            i = this._getNumeric(t),
                            n = "1" === i.charAt(0) ? i : "1".concat(i);
                        return (this.options.separateDialCode && "+" !== t.charAt(0) ? "+".concat(e) : t && "+" !== t.charAt(0) && "1" !== t.charAt(0) && e && "1" === e.charAt(0) && 4 === e.length && e !== n.substr(0, 4) ? e.substr(1) : "") + t
                    }
                }, {
                    key: "_beforeSetNumber",
                    value: function(t) {
                        var e = t;
                        if (this.options.separateDialCode) {
                            var i = this._getDialCode(e);
                            if (i) {
                                null !== this.selectedCountryData.areaCodes && (i = "+".concat(this.selectedCountryData.dialCode));
                                var n = " " === e[i.length] || "-" === e[i.length] ? i.length + 1 : i.length;
                                e = e.substr(n)
                            }
                        }
                        return this._cap(e)
                    }
                }, {
                    key: "_triggerCountryChange",
                    value: function() {
                        this._trigger("countrychange")
                    }
                }, {
                    key: "handleAutoCountry",
                    value: function() {
                        "auto" === this.options.initialCountry && (this.defaultCountry = e.intlTelInputGlobals.autoCountry, this.telInput.value || this.setCountry(this.defaultCountry), this.resolveAutoCountryPromise())
                    }
                }, {
                    key: "handleUtils",
                    value: function() {
                        e.intlTelInputUtils && (this.telInput.value && this._updateValFromNumber(this.telInput.value), this._updatePlaceholder()), this.resolveUtilsScriptPromise()
                    }
                }, {
                    key: "destroy",
                    value: function() {
                        var t = this.telInput.form;
                        if (this.options.allowDropdown) {
                            this._closeDropdown(), this.selectedFlag.removeEventListener("click", this._handleClickSelectedFlag), this.flagsContainer.removeEventListener("keydown", this._handleFlagsContainerKeydown);
                            var i = this._getClosestLabel();
                            i && i.removeEventListener("click", this._handleLabelClick)
                        }
                        this.hiddenInput && t && t.removeEventListener("submit", this._handleHiddenInputSubmit), this.options.autoHideDialCode && (this.telInput.removeEventListener("mousedown", this._handleMousedownFocusEvent), this.telInput.removeEventListener("focus", this._handleFocusEvent), t && t.removeEventListener("submit", this._handleSubmitOrBlurEvent), this.telInput.removeEventListener("blur", this._handleSubmitOrBlurEvent)), this.telInput.removeEventListener("keyup", this._handleKeyupEvent), this.telInput.removeEventListener("cut", this._handleClipboardEvent), this.telInput.removeEventListener("paste", this._handleClipboardEvent), this.telInput.removeAttribute("data-intl-tel-input-id");
                        var n = this.telInput.parentNode;
                        n.parentNode.insertBefore(this.telInput, n), n.parentNode.removeChild(n), delete e.intlTelInputGlobals.instances[this.id]
                    }
                }, {
                    key: "getExtension",
                    value: function() {
                        return e.intlTelInputUtils ? intlTelInputUtils.getExtension(this._getFullNumber(), this.selectedCountryData.iso2) : ""
                    }
                }, {
                    key: "getNumber",
                    value: function(t) {
                        if (e.intlTelInputUtils) {
                            var i = this.selectedCountryData.iso2;
                            return intlTelInputUtils.formatNumber(this._getFullNumber(), i, t)
                        }
                        return ""
                    }
                }, {
                    key: "getNumberType",
                    value: function() {
                        return e.intlTelInputUtils ? intlTelInputUtils.getNumberType(this._getFullNumber(), this.selectedCountryData.iso2) : -99
                    }
                }, {
                    key: "getSelectedCountryData",
                    value: function() {
                        return this.selectedCountryData
                    }
                }, {
                    key: "getValidationError",
                    value: function() {
                        if (e.intlTelInputUtils) {
                            var t = this.selectedCountryData.iso2;
                            return intlTelInputUtils.getValidationError(this._getFullNumber(), t)
                        }
                        return -99
                    }
                }, {
                    key: "isValidNumber",
                    value: function() {
                        var t = this._getFullNumber().trim(),
                            i = this.options.nationalMode ? this.selectedCountryData.iso2 : "";
                        return e.intlTelInputUtils ? intlTelInputUtils.isValidNumber(t, i) : null
                    }
                }, {
                    key: "setCountry",
                    value: function(t) {
                        var e = t.toLowerCase();
                        this.selectedFlagInner.classList.contains(e) || (this._setFlag(e), this._updateDialCode(this.selectedCountryData.dialCode, !1), this._triggerCountryChange())
                    }
                }, {
                    key: "setNumber",
                    value: function(t) {
                        var e = this._updateFlagFromNumber(t);
                        this._updateValFromNumber(t), e && this._triggerCountryChange()
                    }
                }, {
                    key: "setPlaceholderNumberType",
                    value: function(t) {
                        this.options.placeholderNumberType = t, this._updatePlaceholder()
                    }
                }]) && r(n.prototype, s), o && r(n, o), t
            }();
        e.intlTelInputGlobals.getCountryData = function() {
            return a
        };
        var v = function(t, e, n) {
            var a = i.createElement("script");
            a.onload = function() {
                c("handleUtils"), e && e()
            }, a.onerror = function() {
                c("rejectUtilsScriptPromise"), n && n()
            }, a.className = "iti-load-utils", a.async = !0, a.src = t, i.body.appendChild(a)
        };
        e.intlTelInputGlobals.loadUtils = function(t) {
            if (!e.intlTelInputUtils && !e.intlTelInputGlobals.startedLoadingUtilsScript) {
                if (e.intlTelInputGlobals.startedLoadingUtilsScript = !0, "undefined" != typeof Promise) return new Promise(function(e, i) {
                    return v(t, e, i)
                });
                v(t)
            }
            return null
        }, e.intlTelInputGlobals.defaults = u, e.intlTelInputGlobals.version = "14.1.0", t.fn.intlTelInput = function(i) {
            var a, s = arguments;
            return i === n || "object" == typeof i ? this.each(function() {
                if (!t.data(this, "plugin_intlTelInput")) {
                    var n = new p(this, i);
                    n._init(), e.intlTelInputGlobals.instances[n.id] = n, t.data(this, "plugin_intlTelInput", n)
                }
            }) : "string" == typeof i && "_" !== i[0] ? (this.each(function() {
                var e = t.data(this, "plugin_intlTelInput");
                e instanceof p && "function" == typeof e[i] && (a = e[i].apply(e, Array.prototype.slice.call(s, 1))), "destroy" === i && t.data(this, "plugin_intlTelInput", null)
            }), a !== n ? a : this) : void 0
        }
    }, "object" == typeof module && module.exports ? module.exports = t(require("jquery"), window, document) : "function" == typeof define && define.amd ? define(["jquery"], function(e) {
        t(e, window, document)
    }) : t(jQuery, window, document)
};