const capitalize = require('lodash/capitalize');
const lowerize = require('lodash/toLower');

module.exports = () => {



	//
	// ─── 1- CACHING DOM ─────────────────────────────────────────────────────────────
	//
	var dir = $('html').attr('dir');
	var $openIcon = $('.menu-open');
	var $menu = $('.overlay');
	var $closeIcon = $menu.find('.menu-close');
	var menuEnterenceDirection = $menu.attr('data-enternce-direction') || 'left';
	var menuExitDirection = $menu.attr('data-exit-direction') || 'left';
	var menuSpeed = $menu.attr('data-speed') || ' ';
	var animationType = $menu.attr('data-animation-type') || 'slide';
	var enternceAnimation = `${lowerize(animationType)}In${(dir === 'ltr') ? capitalize(menuEnterenceDirection) : capitalize(menuExitDirection)} ${menuSpeed}`;
	var exitAnimation = `${lowerize(animationType)}Out${(dir === 'ltr') ? capitalize(menuExitDirection) : capitalize(menuEnterenceDirection)} ${menuSpeed}`;



	//
	// ─── 2- EVENTS ──────────────────────────────────────────────────────────────────
	// open menu on menu icon clicked
	// close menu on "esc" button, and close icon clicked
	//
	$('body').on('keyup', function (e) {if (e.key === 'Escape') {closeMenu()}});
	$openIcon.on('click', function (e) {openMenu()});
	$closeIcon.on('click', function (e) {closeMenu()});
	$('.overlay__nav--main').on('click', function(e) {
		closeMenu();
	});


	//
	// ─── 3- OPEN AND CLOSE FUNCTIONS ────────────────────────────────────────────────
	//
	function openMenu () {
		if (!$menu.hasClass('open')) {
			$menu.addClass('open').animateCss(enternceAnimation);
			$("body .bodyOverlay").fadeIn();
		}
	}

	function closeMenu () {
		
		if ($menu.hasClass('open')) {
			$("body .bodyOverlay").fadeOut();
			$menu.animateCss(exitAnimation, function () {
				$menu.removeClass(`${exitAnimation} open animated`);
			});
		}
	}
};

