module.exports = function() {
    // var userNavigator = window.navigator.userAgent;
    // var msie = userNavigator.indexOf('MSIE '); // IE lesa than 11
    // var trident = userNavigator.indexOf('Trident/'); // IE more than 11
    // var edge = userNavigator.indexOf('Edge/'); // EDGE
    // if(msie > 0 || trident > 0 || edge > 0) {
    //     $('.mobile-label, .form-float-label-phone label').css({
    //         "top": "0px",
    //         "padding-top": "0.4rem"
    //     });
    //     /*
    //     $('.form-control, .form-float-control, .custom-select, .selectize-select').each(function(index, item) {
    //         if($(item).val() !== "") {
    //             $(item).siblings('label').addClass('inputFocused');
    //         } 
            
            
    //         /*
    //         $(item).on('focus', function() {
    //             $(item).siblings('label').addClass('inputFocused');
                
    //         }).on('blur', function() {
    //             if($(item).val() === "") {
    //                 $(item).siblings('label').removeClass('inputFocused');
    //             }
    //         });
            
           
    //     });
    //         /*
    //         $(item).on('change', function() {
    //             if($(item).val() !== "") {
    //                 $(item).siblings('label').addClass('inputFocused');
    //             } else {
    //                 $(item).siblings('label').removeClass('inputFocused');
    //             }
    //         });
            
            
    //     });
    //     */
       
    //     $(document).on('focus ', '.form-control, .form-float-control, .selectize-select', function() {
    //         $(this).siblings('label').addClass('inputFocused');
    //         if($(this).val() === "") {
    //             $(this).siblings('label').removeClass('inputFocused');
    //         }else {
    //             $(this).siblings('label').addClass('inputFocused');
    //         }
    //     });
    //     $(document).on('blur ', '.form-control, .form-float-control, .selectize-select', function() {
    //         if($(this).val() === "") {
    //             $(this).siblings('label').removeClass('inputFocused');
    //         }else {
    //             $(this).siblings('label').addClass('inputFocused');
    //         }
    //     });


    //     $(document).on('change', '.custom-select, .selectize-select', function() {
    //         if($(this).val() !== "") {
    //             $(this).siblings('label').addClass('inputFocused');
    //         } else {
    //             $(this).siblings('label').removeClass('inputFocused');
    //         }
    //     });
        
    //     $('.form-control, .form-float-control, .custom-select, .selectize-select').each(function(index, item) {
    //         if($(item).val() !== "") {
    //             $(item).siblings('label').addClass('inputFocused');
    //         } 
    //     });

        

    // }

};

