const Arabic  = require('flatpickr/dist/l10n/ar.js').default.ar;
const English = require('flatpickr/dist/l10n/default.js');

module.exports = function () {
    $('.add-new-elem').each(function(index, item) {
        $(item).on('click', function() {
            var $thisElem = $(this);
            var maxNumber = $thisElem.attr('max-number');
            var repeatedElemLength = $thisElem.parent().parent('.create-elem-wrap').find('.repated-element').length;
            console.log(repeatedElemLength)
            if(repeatedElemLength == maxNumber) {
                return false;
                
            } else {
                createElem($thisElem);
            }

             // reset flatpicker init
            $(".inputTimePicker").each(function (index, element) {
                $(element).flatpickr({
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "h:i K",
                    "locale": ($('html').attr('dir') === 'rtl') ? Arabic : English
                });
            });
            $('.inputDatePicker').each(function (index, element) {
                $(element).flatpickr({
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    "locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
                });
            });
        
            $('.inputDatePickerWithRange').each(function (index, element) {
                $(element).flatpickr({
                    mode: "range",
                    minDate: "today",
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    "locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
                });
            });
            
        });
    });

    function createElem(createBtn) {

       
        
        // get big parent of repeated element
        var bigParent = $(createBtn).parent().parent('.create-elem-wrap');
        // get nested parent to append on it
        var nestedParent = bigParent.find('.create-elem-contain');
        // get only first child and repeat it
        var repeatedElemFirst = bigParent.find('.repated-element:first-child');
        var repeatedElemCounter = $('.repated-element').length;
        console.log("repeatedElemCounter ="  +   repeatedElemCounter)
        //console.log(repeatedElemCounter);
        var cloneRepeatedElem = repeatedElemFirst.clone();
        $(cloneRepeatedElem).find('textarea').val('');
        $(cloneRepeatedElem).find('select').val('');

       
          $(cloneRepeatedElem).find('input').each(function() {
            if($(this).attr('id') != undefined && ($(this).attr('id').indexOf("ccPaid") != -1 || $(this).attr('id').indexOf("ccUnPaid")  != -1 )) {

            } else if ($(this).attr('id') != undefined && ($(this).attr('id').indexOf("cc_male") != -1 || $(this).attr('id').indexOf("cc_female")  != -1 )) {

            } else {
                $(this).val('');
            }
          })
            
        
        //cloneRepeated elements and serach for inputs
        var cloneRepeatedElemInputs = $(cloneRepeatedElem).find('input');
        if (cloneRepeatedElemInputs.length) {
            cloneRepeatedElemInputs.each(function(){
                $(this).attr("id", $(this).attr("id") + repeatedElemCounter );
     
                // if condition to check if this experience 
                // section name the input's name like [experience][n][input name ]
                var inputName = $(this).attr("name");
                if (inputName ) {
                    if (inputName.search("languages") > -1) {
                        var changeableNum = inputName.substring(10, inputName.indexOf("]"));
                        var newName = inputName.substring(0,10) +( Number(changeableNum) + repeatedElemCounter) + inputName.substring(11);
                        $(this).attr("name", newName)
                    }   
                    if (inputName.search("role") > -1) {
                        var changeableNum = inputName.substring(5, inputName.indexOf("]"));
                        var newName = inputName.substring(0,5) +( Number(changeableNum) + repeatedElemCounter) + inputName.substring(6);
                        $(this).attr("name", newName)
                    } 
                    if (inputName.search("experience") > -1) {
                        var changeableNum = inputName.substring(11, inputName.indexOf("]"));
                        var newName = inputName.substring(0,11) +( Number(changeableNum) + repeatedElemCounter) + inputName.substring(12);
                        $(this).attr("name", newName)
                    } 
                }
                
                  
             });
        }
        
        var cloneRepeatedElemLabels = $(cloneRepeatedElem).find('label');
        if (cloneRepeatedElemLabels.length) {
            cloneRepeatedElemLabels.each(function(){
                $(this).attr("for", $(this).attr("for") + repeatedElemCounter );   
             });
        }

        var cloneRepeatedElemSelects = $(cloneRepeatedElem).find('select');
        if (cloneRepeatedElemSelects.length) {
            cloneRepeatedElemSelects.each(function(){
                if($(this).parent().hasClass('selectize-to-remove-skills'))
                {
                    require('selectize');
                    var selectizeInit = require('../partials/selectizeInit');
                    var $selectizeToShow = $(this).parent().siblings('.selectize-to-repeat-skills');
                    $(this).parent().remove();
                    $selectizeToShow.removeClass('d-none').removeClass('selectize-to-repeat-skills');                   
                    $selectizeToShow.find('select').addClass('selectize-select');
                    $selectizeToShow.find('select').selectize({
                        create: false,
                        plugins: ['remove_button'],
                        placeholder: $(this).attr('placeholder'),
                        addPrecedence: false,
                    })[0].selectize;
                    // selectizeInit(); // initializing selectize plugin.
                }
                if($(this).parent().hasClass('selectize-to-remove-lang'))
                {
                    require('selectize');
                    var selectizeInit = require('../partials/selectizeInit');
                    var $selectizeToShow = $(this).parent().siblings('.selectize-to-repeat-lang');
                    $(this).parent().remove();
                    $selectizeToShow.removeClass('d-none').removeClass('selectize-to-repeat-lang');                   
                    $selectizeToShow.find('select').addClass('selectize-select');
                    $selectizeToShow.find('select').selectize({
                        create: false,
                        plugins: ['remove_button'],
                        placeholder: $(this).attr('placeholder'),
                        addPrecedence: false,
                    })[0].selectize;
                    // selectizeInit(); // initializing selectize plugin.
                }
                $(this).attr("id", $(this).attr("id") + repeatedElemCounter );
                // var selectId = $(this).attr('id');
                // console.log($(this).siblings('.selectize-control').find('input[type="select-multiple"]'));
                // $(this).siblings('.selectize-control').find('input[type="select-multiple"]').attr('id',selectId+"-selectized")
                // $(this).siblings('label.back_pet').attr('for',selectId+'-selectized');
                var selectName = $(this).attr("name");
                if (selectName.search("languages") > -1) {
                    var changeableNum = selectName.substring(10, selectName.indexOf("]"));
                    var newName = selectName.substring(0,10) +( Number(changeableNum) + repeatedElemCounter) + selectName.substring(11);
                    $(this).attr("name", newName)
                }   
                if (selectName.search("role") > -1) {
                    var changeableNum = selectName.substring(5, selectName.indexOf("]"));
                    var newName = selectName.substring(0,5) +( Number(changeableNum) + repeatedElemCounter) + selectName.substring(6);
                    $(this).attr("name", newName)
                }
                

                
             });
        }
        var cloneRepeatedElemSelects = $(cloneRepeatedElem).find('.form-float-label-group.selectize-to-remove').remove();
        if (cloneRepeatedElemSelects.length) {
            cloneRepeatedElemSelects.each(function(){
        
                $(this).attr("id", $(this).attr("id") + repeatedElemCounter );
                // var selectId = $(this).attr('id');
                // console.log($(this).siblings('.selectize-control').find('input[type="select-multiple"]'));
                // $(this).siblings('.selectize-control').find('input[type="select-multiple"]').attr('id',selectId+"-selectized")
                // $(this).siblings('label.back_pet').attr('for',selectId+'-selectized');
                var selectName = $(this).attr("name");
                if (selectName.search("languages") > -1) {
                    var changeableNum = selectName.substring(10, selectName.indexOf("]"));
                    var newName = selectName.substring(0,10) +( Number(changeableNum) + repeatedElemCounter) + selectName.substring(11);
                    $(this).attr("name", newName)
                }   
                if (selectName.search("role") > -1) {
                    var changeableNum = selectName.substring(5, selectName.indexOf("]"));
                    var newName = selectName.substring(0,5) +( Number(changeableNum) + repeatedElemCounter) + selectName.substring(6);
                    $(this).attr("name", newName)
                }       
             });
        }
        
            
        var cloneRepeatedElemTextAreas = $(cloneRepeatedElem).find('textarea');
        if (cloneRepeatedElemTextAreas.length) {
            cloneRepeatedElemTextAreas.each(function(){
                $(this).attr("id", $(this).attr("id") + repeatedElemCounter );
                var selectName = $(this).attr("name");
                if (selectName.search("languages") > -1) {
                    var changeableNum = selectName.substring(10, selectName.indexOf("]"));
                    var newName = selectName.substring(0,10) +( Number(changeableNum) + repeatedElemCounter) + selectName.substring(11);
                    $(this).attr("name", newName)
                }   
                if (selectName.search("role") > -1) {
                    var changeableNum = selectName.substring(5, selectName.indexOf("]"));
                    var newName = selectName.substring(0,5) +( Number(changeableNum) + repeatedElemCounter) + selectName.substring(6);
                    $(this).attr("name", newName)
                } 
                if (selectName.search("experience") > -1) {
                    var changeableNum = selectName.substring(11, selectName.indexOf("]"));
                    var newName = selectName.substring(0,11) +( Number(changeableNum) + repeatedElemCounter) + selectName.substring(12);
                    $(this).attr("name", newName)
                } 
                
             });
        }
    
         
        // show close element of repeated elements
        var removeElemBtn = $(cloneRepeatedElem).find('.remove-new-elem');
        
        removeElemBtn.removeClass('d-none');
        // append repated element after first child
        nestedParent.append(cloneRepeatedElem);
        // init remove element functionality
        removeElement(removeElemBtn);
        
    
    }
    function removeElement(removeBtn) {
        $(removeBtn).each(function(index, item) {
            $(item).on('click', function() {
                $(this).parent().remove();
            });
        });
    }
    $('.remove-new-elem').each(function(index, item) {
        $(item).on('click', function() {
            $(this).parent().remove();
        });
    });
}


//$(cloneRepeatedElem).find('input[type="radio"').prop('checked', false);

        // DON"T REMOVE THIS SCRIPT NOW
        // var repatedCustomCheckBox = $(cloneRepeatedElem).find('.custom-checkbox');
        // if(repatedCustomCheckBox.length) {

        //     var repatedCustomCheckBoxId = repatedCustomCheckBox.find('input');
        //     repatedCustomCheckBoxId.each(function(index, item) {
        //         var elemCurrId = $(item).attr('id').split('-')[0];
        //         var elemCurrNumber = $(item).attr('id').split('-')[1];
        //         $(item).attr('id', elemCurrId + "-" + elemCurrNumber + 1);


        //         var elemCurrName = $(item).attr('name').split('-')[0];
        //         var elemCurrNameNumb = $(item).attr('name').split('-')[1];
        //         $(item).attr('name', elemCurrName + "-" + elemCurrNameNumb + 1);

        //     });


        //     var repatedCustomCheckBoxLabel = repatedCustomCheckBox.find('label');
        //     repatedCustomCheckBoxLabel.each(function(index, item) {
        //         var labelCurrName = $(item).attr('for').split('-')[0];
        //         var labelCurrNumber = $(item).attr('for').split('-')[1];
        //         $(item).attr('for', labelCurrName + "-" + labelCurrNumber + 1);
        //     });

           
            
        // }