module.exports = function() {
    var userNavigator = window.navigator.userAgent;
    var msie = userNavigator.indexOf('MSIE '); // IE lesa than 11
    var trident = userNavigator.indexOf('Trident/'); // IE more than 11
    var edge = userNavigator.indexOf('Edge/'); // EDGE


    $(document).on('change', '.custom-select, .selectize-select', function() {  
        if($(this).val() !== "") {
            $(this).siblings('label').addClass('inputFocused');
        } else {
            $(this).siblings('label').removeClass('inputFocused');
        }
    });
    
    
    $('.form-control, .form-float-control, .custom-select, .selectize-select').each(function(index, item) {
        if($(item).val() !== "") {
            $(item).siblings('label').addClass('inputFocused');
        } 
    });


    if(msie > 0 || trident > 0 || edge > 0) {
        $('.mobile-label').css({
            "top": "0px",
            "padding-top": "0.6rem"
        });
        $(document).on('focus', '.form-control, .form-float-control', function() {
            $(this).siblings('label').addClass('inputFocused');
        });
        $(document).on('blur', '.form-control, .form-float-control', function() {
            if($(this).val() === "") {
                $(this).siblings('label').removeClass('inputFocused');
            }
        });


        $(document).on('change click focus', '.custom-select, .selectize-select', function() {
            
            if($(this).val() !== "") {
                $(this).siblings('label').addClass('inputFocused');
            } else {
                $(this).siblings('label').removeClass('inputFocused');
            }
        });

        $('.custom-select').on("click touchstart change", () => {
            
            if($(this).val() !== "") {
                $(this).siblings('label').addClass('inputFocused');
            } else {
                $(this).siblings('label').removeClass('inputFocused');
            }
        })
        
        
        $('.form-control, .form-float-control, .custom-select, .selectize-select').each(function(index, item) {
            
            if($(item).val() !== "") {
                $(item).siblings('label').addClass('inputFocused');
            } 
        });
    }
};