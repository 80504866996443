$(document).ready(function () {
  var selector = $('#localeInput')
  $("div").find("[data-locale]").css('display', 'none')
  $("div").find("[data-group-locale]").css('display', 'none')

  $("div").find("[data-locale='" + selector.val() + "']").css('display', 'block')
  $('#localeInput').change(function () {
    $("div").find("[data-locale]").css('display', 'none')
    var optionSelected = $(this).find("option:selected");
    var sum = optionSelected.val()
    $("div").find("[data-locale='" + sum + "']").css('display', 'block')
  })
  $('#localeGroupInput').change(function () {
    $("div").find("[data-locale]").css('display', 'none')
    var optionSelected = $(this).find("option:selected");
    var sumGroup = optionSelected.val()
    $("div").find("[data-locale='" + sumGroup + "']").css('display', 'block')
  })
  // tag group page
  $("div").find("[data-types-group]").addClass('divIsHidden')
  // console.log($('#typesInput').val(), 'value')
  var selectValue = $('#typesInput').val()
  $.each(selectValue, function (index, item) {
    // console.log(item, 'items')
    $("div").find("[data-types-group='" + item + "']").removeClass('divIsHidden')
  })
  if ($('#tagGroupsBoxes').parent().closest('.form-row.mb-4').hasClass('divIsHidden'))
    $('#tagGroupsBoxes').attr('checked', false)
  $('#typesInput').change(function () {
    $("div").find("[data-types-group]").addClass('divIsHidden')
    var typeSelect = $(this).find("option:selected")
    var theType = typeSelect.val();
    var tests = this.selectize.getValue()
    $.each(tests, function (index, value) {
      $("div").find("[data-types-group='" + value + "']").removeClass('divIsHidden')
    })
    console.log($('[data-input-type = "groupInput"]').is(":checked"), 'getting parent');
    if ($(':checkbox').closest().hasClass('divIsHidden')) {
      $(':checkbox').prop("checked", false)
    }
    $.each($('input[type="checkbox"]'), function (index, value) {
      if ($(value).parent().parent().parent().parent().hasClass('divIsHidden')) {
        $(value).prop("checked", false)
      }
    })

  })

});