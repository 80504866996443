//
// ─── EXPORTING MODULE FUNCTIONS ─────────────────────────────────────────────────
//

module.exports = function () {
	$(window).scroll(function () {
		var scrollPX = $(window).scrollTop();
		var $header = $(document).find(`nav.navbar`);
		if (scrollPX >= 1) {
			if ($header.hasClass('home-navbar') && $header.hasClass('bg-transparent')) {
				$header.removeClass('bg-transparent').addClass('bg-primary scrolling shadow-sm');
			}
		} else {
			if ($header.hasClass('home-navbar') && $header.hasClass('bg-primary')) {
				$header.removeClass('bg-primary scrolling shadow-sm').addClass('bg-transparent');
			}
		}
	});
};