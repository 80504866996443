



module.exports = function () {
	const intlTelInput = require('./intlTelInput-jquery.js');
	//const intlTelInput = require('intl-tel-input/build/js/intlTelInput-jquery.js');
	const utils = require('intl-tel-input/build/js/utils.js');
	const isNumber = require('lodash/isNumber');

	$('.phone').each(function (index, item) {
		var $input = $(item);
		$input.intlTelInput();

		function setCode() {
			if($input.val() !== ''){
				$input.val($input.intlTelInput("getNumber"));
			} else {
				$input.val(`+${$input.intlTelInput("getSelectedCountryData").dialCode}`);
			}
		}
		setCode();

		$input.on('countrychange', function() {
			setCode();
		})
		
		
		if ($input.val() !== '') {
			$input.closest('.form-float-label-phone').find('label').addClass('floated');
			$input.val($input.intlTelInput("getNumber"));
		}

		$input.on('focus', function () {
			if (!$input.closest('.form-float-label-phone').find('label').hasClass('floated')) {
				$input.closest('.form-float-label-phone').find('label').addClass('floated');
			}
		});
		$input.on('blur', function (e) {
			// if($input.val() !== '') {
			// 	$input.val($input.intlTelInput("getNumber"));
			// 	$input.closest('.form-float-label-phone').find('input.form-control.form-float-control.d-none').val($input.val());
			// 	console.log($input.val());
			// }
			// else {
			// 	$input.val(`+${$input.intlTelInput("getSelectedCountryData").dialCode}`);
			// }

			if ($input.intlTelInput("getNumber") === `+${$input.intlTelInput("getSelectedCountryData").dialCode}`) {
				$input.val('');
			}
			if ($input.val() === $input.intlTelInput("getNumber").replace(`+${$input.intlTelInput("getSelectedCountryData").dialCode}`, '')){
				$input.val($input.intlTelInput("getNumber"));
				$input.closest('.form-float-label-phone').find('input.form-control.form-float-control.d-none').val($input.val());
			}

			if ($input.val() === '' && $input.closest('.form-float-label-phone').find('label').hasClass('floated')) {
				$input.closest('.form-float-label-phone').find('label').removeClass('floated');
			}
		});


		$input.on('keyup', function () {
			$(this).val($(this).val().replace(/[a-zA-Z]/g, ""));
		}).on('keydown', function () {
			$(this).val($(this).val().replace(/[a-zA-Z]/g, ""));
		})

	});


};



// const intlTelInput = require('intl-tel-input/build/js/intlTelInput-jquery.min.js');
// const utils = require('intl-tel-input/build/js/utils.js');
// const isNumber = require('lodash/isNumber');
// module.exports = function () {

// 	$('.phone').each(function (index, item) {
// 		var $input = $(item);
// 		$input.intlTelInput();

// 		// change input value on country change event from flags dropdown menu.
// 		$input.on('countrychange', function() {
// 			if($input.val() !== ''){
// 				$input.val($input.intlTelInput("getNumber").replace(`+${$input.intlTelInput("getSelectedCountryData").dialCode}`, ''));
// 			}
// 			$input.closest('.form-float-label-phone').find('input:hidden').val($input.intlTelInput("getSelectedCountryData").dialCode);
// 		});

// 		// reset input floating label if input value is empty.
// 		if ($input.val() !== '') {
// 			$input.closest('.form-float-label-phone').find('label').addClass('floated');
// 			$input.val($input.intlTelInput("getNumber"));
// 		}


// 		// handeling input floating label on input focus.
// 		$input.on('focus', function () {
// 			if (!$input.closest('.form-float-label-phone').find('label').hasClass('floated')) {
// 				$input.closest('.form-float-label-phone').find('label').addClass('floated');
// 			}
// 		});

// 		// manipulat input value on input blur.
// 		$input.on('blur', function (e) {
// 			// if input value equal to just country code, reset input value.
// 			if ($input.intlTelInput("getNumber") === `+${$input.intlTelInput("getSelectedCountryData").dialCode}`) {
// 				$input.val('');
// 			}

// 			if ($input.val() === $input.intlTelInput("getNumber").replace(`+${$input.intlTelInput("getSelectedCountryData").dialCode}`, '')){
// 				$input.val($input.intlTelInput("getNumber").replace(`+${$input.intlTelInput("getSelectedCountryData").dialCode}`, ''));
// 			}

// 			if ($input.val() === '' && $input.closest('.form-float-label-phone').find('label').hasClass('floated')) {
// 				$input.closest('.form-float-label-phone').find('label').removeClass('floated');
// 			}
// 		});


// 		$input.on('keyup', function () {
// 			$(this).val($(this).val().replace(/[a-zA-Z]/g, ""));
// 		}).on('keydown', function () {
// 			$(this).val($(this).val().replace(/[a-zA-Z]/g, ""));
// 		})

// 	});


// };