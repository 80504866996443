//
// ─── EXPORTING MODULE FUNCTIONS ─────────────────────────────────────────────────
//



function set_user_location_to_local_storage(location, cb) {
	if(localStorage.getItem('lat')) {
		if (cb) {cb()}
	} else {
		Object.keys(location).forEach((value, index) => {
			localStorage.setItem(value, location[value]);
			if (index === Object.keys(location).length - 1) {
				if (cb) {cb()}
			}
		})
	}
}

function get_all_location_data(lat, long, cb) {
	$.ajax({
		url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyAceZt91hCfdVh6oFAXQfNVRRdlXNJJgLs`
	}).done(function (data) {
		var location = {
			formatted_address: data.results[0].formatted_address,
			lat: data.results[2].geometry.location["lat"] || lat,
			long: data.results[2].geometry.location["lng"] || long,
			city_lat: data.results[data.results.length - 2].geometry.location["lat"],
			city_long: data.results[data.results.length - 2].geometry.location["lng"],
			country_lat: data.results[data.results.length - 1].geometry.location["lat"],
			country_long: data.results[data.results.length - 1].geometry.location["lng"],
			area_lat: data.results[data.results.length - 3].geometry.location["lat"],
			area_long: data.results[data.results.length - 3].geometry.location["lng"]
		}
		if(cb) {
			cb(location);
		}
	});
};

function ask_user_to_access_location(location_input, cb) {
	if ("geolocation" in navigator) {
		navigator.geolocation.getCurrentPosition(function (position)  {
			get_all_location_data(position.coords.latitude, position.coords.longitude, function (result) {
				set_user_location_to_local_storage(result, function () {
					set_initial_location_inputs_values(location_input, function () {
						if(cb){cb()}
					});
				});
			});
		}, function (err) {
			if(cb){cb()}
			return console.log(err);
		});
	} else {
		if(cb){cb()}
		console.log('geolocation is not enabled on this browser');
	}
}


function set_location_inputs_values_on_change (location_input, location_data) {
	$(location_input).each(function (index, item) {
		$(item).siblings('.location-lat').val(location_data.lat);
		$(item).siblings('.location-long').val(location_data.long);

		$(item).siblings('.location-city-lat').val(location_data.city_lat);
		$(item).siblings('.location-city-long').val(location_data.city_long);

		$(item).siblings('.location-country-lat').val(location_data.country_lat);
		$(item).siblings('.location-country-long').val(location_data.country_long);

		$(item).siblings('.location-area-lat').val(location_data.area_lat);
		$(item).siblings('.location-area-long').val(location_data.area_long);
	});
};


function set_initial_location_inputs_values(location_input, cb) {
	$(location_input).each(function (index, item) {
		if ($(item).is('[data-location-type]') && $(item).attr('data-location-type') === 'search') {
			$(item).val(localStorage.getItem('formatted_address'));
			$(item).siblings('.location-lat').val(localStorage.getItem('lat'));
			$(item).siblings('.location-long').val(localStorage.getItem('long'));

			$(item).siblings('.location-city-lat').val(localStorage.getItem('city_lat'));
			$(item).siblings('.location-city-long').val(localStorage.getItem('city_long'));

			$(item).siblings('.location-country-lat').val(localStorage.getItem('country_lat'));
			$(item).siblings('.location-country-long').val(localStorage.getItem('country_long'));

			$(item).siblings('.location-area-lat').val(localStorage.getItem('area_lat'));
			$(item).siblings('.location-area-long').val(localStorage.getItem('area_long'));
		}
	});
	if(cb) {cb()}
};


module.exports = {
	initAutocomplete: function () {
		var $location_input = $('.location-search');

		ask_user_to_access_location($location_input, function () {
			// caching dom
			$location_input.each(function (index, item) {


				//
				// ─── 1- OPTIONS ────────────────────────────────────────────────────────────────────
				//
				var map_options = {
					center: {
						lat: Number($(item).siblings('.location-lat').val()) || 23.8859,
						lng: Number($(item).siblings('.location-long').val()) || 45.0792
					},
					zoom: 3
				};
				var pin_options = {
					url: './images/icons/map-pin-red.png',
					size: new google.maps.Size(71, 71),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(17, 34),
					scaledSize: new google.maps.Size(60, 60)
				};
				var autocomplete_options = {
					types: [],
					componentRestrictions: {
						country: ['sa'] // you can add countries up to 5 places.
					}
				};
				var marker_options = {
					anchorPoint: new google.maps.Point(0, -29),
					draggable: true
				}



				var $mapUi = $(item).parent().siblings().find('.map');
				var map;


				//
				// ─── 2- MAP, AUTOCOMPLETE, MARKER INSTANCES ─────────────────────────────────────
				//
				if ($mapUi.length) {map = new google.maps.Map($mapUi[0], map_options);}
				var autocomplete = new google.maps.places.Autocomplete($(item)[0], autocomplete_options);
				autocomplete.bindTo('bounds', map);
				var marker = new google.maps.Marker(marker_options);
				marker.setMap(map);



				//
				// ─── 3- EVENTS BINDING ──────────────────────────────────────────────────────────
				//
				$(item).keydown(function (e) {
					if (e.which == 13 && $('.pac-container:visible').length) return false;
				});

				google.maps.event.addListener(marker, 'dragend', function () {
					get_all_location_data(marker.getPosition().lat(), marker.getPosition().lng(), function (result) {
						set_location_inputs_values_on_change($(item), result);
					});
				});

				google.maps.event.addListener(autocomplete, 'place_changed', function () {
					marker.setVisible(false);
					var place = autocomplete.getPlace();
					if (!place.geometry) {
						window.alert("Autocomplete's returned place contains no geometry");
						return;
					}

					// If the place has a geometry, then present it on a map.
					if (place.geometry.viewport) {
						map.fitBounds(place.geometry.viewport);
					} else {
						map.setCenter(place.geometry.location);
						map.setZoom(10); // Why 10? Because it looks good.
					}
					marker.setIcon( /** @type {google.maps.Icon} */(pin_options));
					marker.setPosition(place.geometry.location);
					marker.setVisible(true);

					var address = '';
					if (place.address_components) {
						address = [
							(place.address_components[0] && place.address_components[0].short_name || ''),
							(place.address_components[1] && place.address_components[1].short_name || ''),
							(place.address_components[2] && place.address_components[2].short_name || '')
						].join(' ');
					}


					var latitude = place.geometry.location.lat();
					var longitude = place.geometry.location.lng();

					get_all_location_data(latitude, longitude, function (result) {
						set_location_inputs_values_on_change($(item), result);
					});
				});
			});
		});

	},
	initMap: function () {
		var direction = $('html').attr('dir');
		var $map = $("#map");
		// Map options
		var options = {
			zoom: Number($map.attr('data-map-zoom')) || 6,
			center: {
				lat: Number($map.attr('data-map-lat')),
				lng: Number($map.attr('data-map-lang'))
			},
			streetViewControl: false,
			mapTypeControl: false,
			zoomControl: true,
			zoomControlOptions: {
				position: (direction === 'ltr') ? google.maps.ControlPosition.LEFT_CENTER : google.maps.ControlPosition.RIGHT_CENTER
			},
			scaleControl: false,
			fullscreenControl: false
		}
		// icon options
		var icon = {
			url: $map.attr("data-map-pin") || "", // url
			scaledSize: new google.maps.Size(40, 57), // scaled size
			origin: new google.maps.Point(0, 0), // origin
			anchor: new google.maps.Point(0, 0) // anchor
		};

		var map;

		if ($map.length) {
			map = new google.maps.Map($map[0], options);
		}
		// New map
		// Array of markers
		var markers = [{
			coords: {
				lat: Number($map.attr('data-map-lat')),
				lng: Number($map.attr('data-map-lang'))
			},
			icon: icon
		}];

		// Loop through markers
		for (var i = 0; i < markers.length; i++) {
			addMarker(markers[i]);
		}

		// Add Marker Function
		function addMarker(props) {
			var marker = new google.maps.Marker({
				position: props.coords,
				map: map,
				icon: props.icon
			});
			// Check for customicon
			if (props.icon) {
				// Set icon image
				marker.setIcon(props.icon);
			}
		}
	}
}



var inputLocation = $(".form-control.location-search");
$(document).on(' blur keyup', ".form-control.location-search", function() {
	console.log("value is empty")
	if ($(this).val() == "") {
		console.log("input is empty")
		$(this).siblings('input').val("");
		
		
	}
});


