const lowerCase = require("lodash/lowerCase");
const isEqual = require("lodash/isEqual");

module.exports = function() {
	$('select.selectize-select').each(function (index, item) {
		var singleSelect = $(item).selectize({
			create: false,
			plugins: ['remove_button'],
			placeholder: $(this).attr('placeholder'),
			addPrecedence: false,
		})[0].selectize;

		var value = singleSelect.getValue();
		if(value == '') {
			singleSelect.$control.parent().parent().find('label').addClass('d-none bg-white');
		}

		singleSelect.on('change', function () {
			var $label = singleSelect.$control.parent().parent().find('label');
			var $hiddenInput = singleSelect.$input.parent().find('input.form-control.form-float-control.d-none');
			value = singleSelect.getValue();

			if (value != '' && $label.length && $label.hasClass('d-none')) {
				$label.removeClass('d-none').animateCss("fadeIn faster", function () {
					$label.addClass('bg-white');
				});
			}
			if (value == '' && $label.length && !$label.hasClass('d-none')) {
				$label.animateCss("fadeOut faster", function (){
					$label.addClass('d-none').removeClass('bg-white');
				});
			}



			if ($hiddenInput.length) {
				$hiddenInput.val(value);
			}

			if (isEqual(singleSelect.$input.attr('id'), "formInput_RFPsShippingRequired")) {
				let $locationInput = singleSelect.$input.closest("form").find("#formInput_RFPsShippingLocation").parent();
				if (isEqual(singleSelect.$input.val(), '0') || isEqual(singleSelect.$input.val(), "")) {
					if (!$locationInput.hasClass("d-none")) {
						$locationInput.animateCss("fadeOut faster", function () {
							$locationInput.addClass('d-none');
							$locationInput.find("input").removeAttr("required");
							$locationInput.find("input").val("");
						});
					}
				} else {
					if ($locationInput.hasClass("d-none")) {
						$locationInput.removeClass('d-none').animateCss("fadeIn faster", function () {
							$locationInput.find("input").attr("required", true);
						});
					}
				}
			}

		});
	});
};