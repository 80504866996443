//
// ─── LOADING DEPENDENCIES ───────────────────────────────────────────────────────
//

const Arabic = require('flatpickr/dist/l10n/ar.js').default.ar;
const English = require('flatpickr/dist/l10n/default.js');


//
// ─── EXPORTING MODULE FUNCTION ──────────────────────────────────────────────────
//

module.exports = function () {


	$(".inputTimePicker").each(function (index, element) {
		$(element).flatpickr({
			enableTime: true,
			noCalendar: true,
			dateFormat: "h:i K",
			"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English
		});
	});




	$('.inputDatePicker').each(function (index, element) {
		$(element).flatpickr({
			enableTime: false,
			dateFormat: "Y-m-d",
			"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
		});
	});


	$('.inputDatePickerWithRange').each(function (index, element) {
		$(element).flatpickr({
			mode: "range",
			minDate: "today",
			enableTime: false,
			dateFormat: "Y-m-d",
			"locale": ($('html').attr('dir') === 'rtl') ? Arabic : English, // get site language from html direction
		});
	});

}